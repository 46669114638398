<template>
  <div class="empty">
    <img src="@/assets/images/empty.png">
    <p>暂无数据</p>
  </div>
</template>

<script>
export default {
  name: 'Empty'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.empty {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    img {
      width: 50%;
    }
  }
</style>
