<template>
  <div class="filterBox-row">
    <div class="filterBox-left" :style="leftWidth?`width: ${leftWidth}%`:'width: 7%'">{{ name }}</div>
    <div :class="activeIndex == index? 'filterBox-itemSelected' : 'filterBox-item'" v-for="(b, index) in btnListFilter" :key="b.name" @click="activeIndex = index;handleClickBtn(b)">
      {{ b.name }} <span :class="activeIndex == index ? 'filterBox-numSelected' : 'filterBox-num'" v-if="b.count">{{ b.count }}</span>
    </div>
    <div class="moreBox" @click="showMoreBtn" v-if="moreBtnVisible">
      更多
      <img class="moreBox-img" src="@/assets/images/more.png">
    </div>
  </div>
</template>

<script>
export default {
  name: "filterBox",
  props: {
    name: String,
    btnList: Array,
    is_limit: Boolean,
    leftWidth: Number
  },
  data() {
    return {
      activeIndex: 0,
      limitNum: 6,
      btnListFilter: [],
      moreBtnVisible: false,
    }
  },
  watch: {
    btnList: {
      handler(newVal, oldVal) {
        if (this.is_limit==true) {
          if (newVal.length > 6) {
            this.moreBtnVisible = true
            this.btnListFilter = newVal.slice(0,6)
          }
        } else {
          this.btnListFilter = newVal
        }
        
      }
    }
  },
  methods: {
    resetIndex() {
      this.activeIndex = 0
    },
    showMoreBtn() {
      if (this.limitNum >= this.btnList.length) {
        this.moreBtnVisible = false
      } else {
        this.limitNum += 5
        this.btnListFilter = this.btnList.slice(0, this.limitNum)
      }
    },
    handleClickBtn(b) {
      console.log("btn", b)
      this.$emit("clickBtn", b)
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.filterBox {
    &-row {
      display: flex;
      margin-bottom: 10px;
      flex-wrap: wrap;
    }
    &-left {
      width: 112px;
      display: flex;
    }
    &-num, &-numSelected {
    color: rgb(64, 149, 229);
    margin-left: 10px;
  }
  &-numSelected {
    color: white;
  }
    &-item,&-itemSelected {
      cursor: pointer;
      padding: 2px 5px 2px 5px;
      border: 1px solid rgb(64, 149, 229);
      border-radius: 4px;
      margin-left: 10px;
      margin-bottom: 10px;
      flex-shrink: 0;
    }
    &-itemSelected {
      background-color: rgb(64, 149, 229);
      color: white
    }
  }
  .moreBox {
    cursor: pointer;
    margin-left: 30px;
    padding-top: 3px;
    &-img {
      width: 12px;
      height: 12px;
    }
  }
</style>
