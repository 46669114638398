<template>
  <div class="productEcharts" v-if="lineChart2DataAllType['income'].length != 0">
    <p class="bombBox-content_v2-subtitle">
      营收构成
    </p>
    <div class="selectAndChart">
      <div class="chartSelect2">
        <div :class="chartSelect2 == 0 ? 'chartSelect-itemSelected' : 'chartSelect-item'
          " @click="handleChartSelect2(0)">
          营业收入
        </div>
        <div :class="chartSelect2 == 1 ? 'chartSelect-itemSelected' : 'chartSelect-item'
          " @click="handleChartSelect2(1)">
          营业利润
        </div>
      </div>
      <div class="lineChart2" ref="lineChart2"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductBarEcharts",
  props: {
    apiDataLineChart: Object,
    apiYearList: Array,
  },
  data() {
    return {
      lineChart2: "",
      chartSelect2: 0,
      lineChart2YearList: [],
      product_legend: [],
      lineChartData2: [],
      lineChart2DataAllType: {
        income: [],
        profit: [],
      },
    };
  },
  watch: {
    // apiDataLineChart: {
    //   deep: true,
    //   handler(newVal, oldVal) {
    //     this.chartSelect2 = 0;
    //     this.lineChart2DataAllType = {
    //       income: [],
    //       profit: [],
    //     };
    //     this.lineChart2YearList = this.apiYearList;
    //     for (let key in newVal) {
    //       let val_income = [];
    //       let val_profit = [];
    //       //   去除金额的逗号
    //       for (let k in newVal[key]) {
    //         val_income.push(newVal[key][k].income.toString().replace(/,/g, ""));
    //         val_profit.push(newVal[key][k].profit.toString().replace(/,/g, ""));
    //       }
    //       this.lineChart2DataAllType.income.push({
    //         name: key,
    //         type: "bar",
    //         stack: "总量",
    //         barMaxWidth: 35,
    //         barGap: "10%",
    //         label:{
    //           show: true,
    //           textStyle:{
    //           color:'black'
    //       }
    //         },
    //         itemStyle: {
    //           normal: {
    //             label: {
    //               show: true,
    //               textStyle: {
    //                 color: "black",
    //               },
    //               position: "insideTop",
    //               formatter: function(p) {
    //                 return p.value > 0 ? p.value : "";
    //               },
    //             },
    //           },
    //         },
    //         data: val_income,
    //       });
    //       this.lineChart2DataAllType.profit.push({
    //         name: key,
    //         type: "bar",
    //         stack: "总量",
    //         barMaxWidth: 35,
    //         barGap: "10%",
    //         label:{
    //           show: true,
    //           textStyle:{
    //           color:'black'
    //       }
    //         },
    //         itemStyle: {
    //           normal: {
    //             label: {
    //               show: true,
    //               textStyle: {
    //                 color: "black",

    //               },
    //               position: "insideTop",
    //               formatter: function(p) {
    //                 return p.value > 0 ? p.value : "";
    //               },
    //             },
    //           },
    //         },
    //         data: val_profit,
    //       });
    //     }

    //       },
    // },
    apiDataLineChart: {
      deep: true,
      handler(newVal, oldVal) {
        this.chartSelect2 = 0;
        this.lineChart2DataAllType = {
          income: [],
          profit: [],
        };
        this.lineChart2YearList = this.apiYearList;
        for (let key in newVal) {
          let val_income = [];
          let val_profit = [];
          //   去除金额的逗号 X.X ↓ 添加方法对数据进行排序（使得for in 遍历对象按照顺序）
          for (let k in this.objectOrder(newVal[key])) {
            val_income.push(newVal[key][k].income.toString().replace(/,/g, ""));
            val_profit.push(newVal[key][k].profit.toString().replace(/,/g, ""));
          }
          this.lineChart2DataAllType.income.push({
            name: key,
            type: "bar",
            stack: "总量",
            barMaxWidth: 35,
            barMinHeight: 1,
            barGap: "10%",
            itemStyle: {
              normal: {
                label: {
                  show: false,
                  textStyle: {
                    color: "#fff",
                  },
                  position: "insideTop",
                  formatter: function (p) {
                    return p.value > 0 ? p.value : "";
                  },
                },
              },
            },
            data: val_income,
          });
          this.lineChart2DataAllType.profit.push({
            name: key,
            type: "bar",
            stack: "总量",
            barMaxWidth: 35,
            barGap: "10%",
            itemStyle: {
              normal: {
                label: {
                  show: false,
                  textStyle: {
                    color: "#fff",
                  },
                  position: "insideTop",
                  formatter: function (p) {
                    return p.value > 0 ? p.value : "";
                  },
                },
              },
            },
            data: val_profit,
          });
        }
        this.lineChartData2 = this.lineChart2DataAllType.income;
        console.log("渲染数据", this.lineChartData2);
        this.$nextTick(() => {
          if (this.lineChart2DataAllType.income.length != 0) {
            console.log("渲染");
            this.setOriginBarChart();
            this.setBarChart();
          }
        });
      },
    },
  },
  methods: {
    // X.X 对对象进行排序函数
    objectOrder(obj) {//排序的函数
      var newkey = Object.keys(obj).sort(); //先用Object内置类的keys方法获取要排序对象的属性名，再利用Array原型上的sort方法对获取的属性名进行排序，newkey是一个数组
      var newObj = {};//创建一个新的对象，用于存放排好序的键值对
      for (var i = 0; i < newkey.length; i++) {//遍历newkey数组
        newObj[newkey[i]] = obj[newkey[i]];//向新创建的对象中按照排好的顺序依次增加键值对
      }
      return newObj;//返回排好序的新对象
    },
    // 点击tab切换显示类型
    handleChartSelect2(val) {
      this.chartSelect2 = val;
      switch (val) {
        case 0:
          this.lineChartData2 = this.lineChart2DataAllType["income"];
          break;
        case 1:
          this.lineChartData2 = this.lineChart2DataAllType["profit"];
          break;
        default:
          break;
      }
      this.setBarChart();
    },
    // echart初始化
    setOriginBarChart() {
      this.lineChart2 = echarts.init(this.$refs.lineChart2);
    },
    // 渲染echart数据
    setBarChart() {
      let option = {
        color: [
          "#546fc6",
          "#377f7e",
          "#8083f8",
          "#557721",
          "#347baf",
          "#3ba372",
          "#fc8452",
          "#9a60b2",
          "#e97ccb",
          "#dd868f",
          "#91cb74",
          "#54BCBD",
          "#ee6666",
          "#73c0de",
          "#3f95e5",
        ],
        label: {
          show: true,
          textStyle: {
            color: 'black'
          },
          formatter: function (params) {
            return params + '亿'
          }
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            textStyle: {
              color: "#fff",
            },
          },
          formatter: function (params) {
            var res = `${params[0].name} <br/>`;
            for (const item of params) {
              if (item.value !== '0') {
                res += `<span style="background: ${item.color}; height:10px; width: 10px; border-radius: 
                50%;display: inline-block;margin-right:10px;"></span> ${item.seriesName} ：${(item.value / 100000000).toFixed(2)}亿<br/>`;
              }
            }
            return res
          },
          // valueFormatter: (value) => value,
          position: function (point, params, dom, rect, size) {
            // 鼠标坐标和提示框位置的参考坐标系是：以外层div的左上角那一点为原点，x轴向右，y轴向下
            // 提示框位置
            var x = 0; // x坐标位置
            var y = 0; // y坐标位置

            // 当前鼠标位置
            var pointX = point[0];
            var pointY = point[1];

            // 外层div大小
            // var viewWidth = size.viewSize[0];
            // var viewHeight = size.viewSize[1];

            // 提示框大小
            var boxWidth = size.contentSize[0];
            var boxHeight = size.contentSize[1];

            // boxWidth > pointX 说明鼠标左边放不下提示框
            if (boxWidth > pointX) {
              x = 5;
            } else {
              // 左边放的下
              x = pointX - boxWidth;
            }
            // boxHeight > pointY 说明鼠标上边放不下提示框
            if (boxHeight > pointY) {
              y = 5;
            } else {
              // 上边放得下
              y = pointY - boxHeight;
            }

            return [x, y];
          },

          enterable: true,
          hideDelay: 200,
          //   confine: true,
          extraCssText:
            "width:60%;max-height:80%;white-space: normal; word-break: break-all;overflow-y: auto;",
        },
        grid: {
          borderWidth: 0,
          left: "center",
          height: "75%",
          width: "78%",
          top: "center",
          // bottom:"20%",
          textStyle: {
            color: "#fff",
          },
        },
        legend: {
          // top: "11%",
          type: "scroll",
          left: "center",
          width: "75%",
          //   data: this.matchAreaLegends,
        },
        xAxis: [
          {
            type: "category",
            axisLabel: {
              interval: 0, //代表显示所有x轴标签显示
              // rotate: 20, // 倾斜旋转
              //   fontSize: this.fontSize(0.14),
              color: "#000",
            },
            axisLine: {
              lineStyle: {
                color: "#000",
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitArea: {
              show: false,
            },
            data: this.lineChart2YearList,
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              interval: 500, //代表显示所有x轴标签显示
              // rotate: 20, // 倾斜旋转
              //   fontSize: this.fontSize(0.14),
              color: "#000",
              formatter: function (value) {
                if (value == 0) return value;
                if (Math.abs(value) < 100000000) {
                  return (value / 10000000).toFixed(1) + "千万";
                } else {
                  return (value / 100000000).toFixed(1) + "亿";
                }
              },
            },

            axisLine: {
              show: true,
              lineStyle: {
                color: "#000",
              },
            },
            splitLine: {
              //网格线
              show: false,
            },
            axisTick: {
              show: true, //不显示坐标轴刻度线
            },
            splitArea: {
              show: false,
            },
          },
        ],
        dataZoom: [
          {
            show: false,
            height: 30,
            xAxisIndex: [0],
            bottom: 30,
            start: 0,
            end: 100,
            handleIcon:
              "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z",
            handleSize: "100%",
            handleStyle: {
              color: "#d3dee5",
            },
            textStyle: {
              color: "#fff",
            },
            borderColor: "#90979c",
          },
          //   {
          //     type: "inside",
          //     show: true,
          //     height: 10,
          //     start: 1,
          //     end: 35,
          //   },
        ],
        series: this.lineChartData2,
      };

      this.lineChart2.setOption(option);
    },
    echartResize() {
      if (this.lineChart2) {
        echarts.init(this.$refs.lineChart2).resize();
      }
    },
  },
  mounted() {
    // let timer = null;
    // window.onresize = () => {
    //   console.log("resize");
    //   if (!timer) {
    //     timer = setTimeout(() => {
    //       console.log("窗口变化pppp");
    //       // if (this.lineChart2) {
    //       //   echarts.init(this.$refs.lineChart2).resize();
    //       // }
    //       this.lineChart2.resize()
    //       // this.wordCloud.resize()
    //       timer = null;
    //     }, 500);
    //   }
    // };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.productEcharts {
  .selectAndChart {
    display: flex;
    margin-left: 20px;
    margin-top: 20px;
  }

  .chartSelect,
  .chartSelect2 {
    width: 20%;
    height: 8vw;
    border-top: 1px solid rgb(179, 179, 182);
    // border-bottom: none;
    display: flex;
    flex-direction: column;

    // justify-content: space-between;
    &-item,
    &-itemSelected {
      border-bottom: 1px solid rgb(179, 179, 182);
      border-right: 1px solid rgb(179, 179, 182);
      border-left: 1px solid rgb(179, 179, 182);
      height: 15.8%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: 13px;
      // padding: 2px 0 2px 0;
      // &:hover {
      //   background-color: #01a4d9;
      // }
    }

    &-itemSelected {
      background-color: #01a4d9;
      color: white;
    }
  }

  .chartSelect2 {
    width: 20%;
    height: 10vw;
    margin-top: 1.5vw;
  }

  .lineChart2 {
    width: 75%;
    margin-left: 10px;
    height: 13vw;
    border: 1px solid rgb(179, 179, 182);
  }
}
</style>
