<template>
  <!-- 企业子公司组件 -->
  <div class="EnterpriseSubsidiarybody">
    <div class="SubsidiaryOf">
      <div
        class="EnterpriseSubsidiaryContent"
        v-if="EnterpriseSubsidiaryData.length > 0"
      >
        <div
          class="ContentOf"
          v-for="(item, index) in EnterpriseSubsidiaryData"
          :key="index"
        >
          <div
            class="contentLeft"
            :style="item.labelList.length > 0 ? 'margin-bottom:10px;' : ''"
          >
            <div class="iconColor"></div>
            <p>{{ item.companyName }}</p>
          </div>
          <div class="contentRight">
            <span v-for="(labelItem, index) in item.labelList" :key="index">{{
              labelItem[0]
            }}</span>
          </div>
        </div>
      </div>
      <div v-else class="EnterpriseSubsidiaryContent" style="text-align: center">暂无数据</div>
    </div>
  </div>
</template>
<script>
import api from "@/api/api";
export default {
  data() {
    return {
      activeNames: ["1"],
      controlIcon: true,
    };
  },
  props: {
    EnterpriseSubsidiaryData: Array,
  },
  methods: {},
  mounted() {
    // let params = {
    //     'company_id':this.$store.state.EnterpriseData,
    //     // 'page_size':100,
    // }
    // api.getEnterpriseSubsidiary(params).then((res) => {
    //     console.log('getEnterpriseSubsidiary...res:',res)
    //     res.data.forEach(element => {
    //         this.EnterpriseSubsidiaryData.push({
    //             'companyName':element.full_name,
    //             // 'labelList':[["空调柜机", 0.800745016169307], ["冷风扇", 0.7929233697654426], ["新风装置", 0.7871331204715707], ["空调器领域", 0.7848806746080478], ["滚刷机构", 0.7780885423062752], ["气泡水机", 0.776571902247329], ["排气阀组件", 0.7598990953375087], ["空调领域", 0.7434023087314257], ["智能家电领域", 0.7250368414589217], ["洗衣系统", 0.7156985549961111]],
    //             'labelList':element.patent_coarse,
    //         })
    //     })
    // })
  },
};
</script>
<style lang="scss">
.EnterpriseSubsidiarybody {
  margin-bottom: 15px;
  .EnterpriseSubsidiaryContent {
    background: #f7f7f7;
    padding: 10px;
    .ContentOf {
      margin-bottom: 10px;
      margin-left: 0rem;
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      .contentLeft {
        // flex: 2;
        display: flex;
        align-items: center;
        // margin-bottom: 10px;
        .iconColor {
          min-height: 10px;
          min-width: 10px;
          // background-color: #111;
          margin-right: 10px;
          background-color: #2bc4c4;
        }
      }
      .contentRight {
        // flex: 2;
        margin-left: 15px;
        span {
          // font-size: 10px;
          background-color: #d1e1ee;
          color: rgb(64, 149, 229);
          margin-right: 5px;
          padding: 5px;
          border-radius: 6px;
        }
      }
    }
  }
}
</style>
