<template>
  <div class="companyInfo">
    <div id="introduce" class="introduce">
      <div class="companyDetailHori" v-loading="loading">
        <!-- <el-tabs v-model="activeTab" @tab-click="handleClickTab">
      <el-tab-pane label="基本信息" name="1"></el-tab-pane>
      <el-tab-pane label="企业舆情" name="2"></el-tab-pane>
      <el-tab-pane label="产业链条" name="3"> </el-tab-pane>
      <el-tab-pane label="企业招聘" name="4"></el-tab-pane>
      <el-tab-pane label="专利布局" name="5"></el-tab-pane>
      <el-tab-pane label="财务收入" name="6"></el-tab-pane>
    </el-tabs> -->
        <div class="detail">
          <div class="detailTop" v-loading="baseInfoLoading">
            <!-- <img src="@/assets/img/companyLogo.png" alt /> -->
            <!-- <div class="compLogo">
              <p>{{ compNameForLogo }}</p>
            </div> -->
            <div class="des">
              <div style="display: flex; align-items: center">
                <h1>
                  {{ companyInfo.entity_name }}
                </h1>
                <!-- <Star
                  class="companyDetailHori-star"
                  :apiData="companyInfo"
                  :type="'company'"
                ></Star>
              </div>
              <p>
                <span>电话：{{ companyInfo.telephone || "暂无" }}</span>
                <span style="cursor: pointer" @click="goto(companyInfo.website)"
                  >官网： {{ companyInfo.website || "暂无" }}</span
                >
              </p>
              <p>
                <span>邮箱：{{ companyInfo.email || "暂无" }}</span>
                <span>地址：{{ companyInfo.office_addr || "暂无" }}</span>
              </p> -->
              </div>
            </div>
          </div>
          <el-radio-group v-model="activeTab" size="small" @change="handleClickTab" style="margin: 10px 0 10px 15px">
            <el-radio-button label="基本信息"></el-radio-button>
            <el-radio-button label="产业布局"></el-radio-button>
            <el-radio-button label="产业链条"></el-radio-button>
            <el-radio-button label="企业舆情"></el-radio-button>
            <!-- <el-radio-button label="企业风险"></el-radio-button> -->
            <el-radio-button label="专利布局"></el-radio-button>
            <el-radio-button label="企业招聘"></el-radio-button>
            <el-radio-button label="财务收入"></el-radio-button>
          </el-radio-group>
          <div style="display: flex;width: 98%;margin-left: 1%" v-if="activeTab == '基本信息'" v-loading="baseInfoLoading">
            <el-collapse v-model="baseActiveNames" @change="handleChange" style="width: 100%">
              <el-collapse-item title="工商信息" name="1">
                <div class="detail">
                  <div class="detail-mask" v-if="false">
                    <p>当前未收录该公司的详细信息</p>
                  </div>
                  <table class="baseInfo">
                    <tbody>
                      <tr>
                        <td>法定代表人</td>
                        <td>
                          {{ companyInfo.legal_representative || "暂无" }}
                        </td>
                        <td>公司类别</td>
                        <td>
                          {{ companyInfo.company_type &&
                            companyInfo.company_type
                              .replace("(", "（")
                              .replace(")", "）") || "暂无"
                          }}
                        </td>
                        <td>成立日期</td>
                        <td>{{ companyInfo.founded_date || "暂无" }}</td>
                      </tr>
                      <tr>
                        <td>注册资本</td>
                        <td>{{ companyInfo.regis_capital || "暂无" }}</td>
                        <td>纳税人识别号</td>
                        <td>{{ companyInfo.regis_code || "暂无" }}</td>
                        <td>所属地区</td>
                        <td>{{ companyInfo.headquater || "暂无" }}</td>
                      </tr>
                      <tr>
                        <td>参保人数</td>
                        <td>{{ companyInfo.employees || "暂无" }}</td>
                        <td>公司英文</td>
                        <td>{{ companyInfo.name_en || "暂无" }}</td>
                        <td>上市板块</td>
                        <td>
                          {{
                            companyInfo.board == ""
                            ? "未上市"
                            : companyInfo.board
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>电话</td>
                        <td>{{ companyInfo.telephone || "暂无" }}</td>
                        <td>邮箱</td>
                        <td>{{ companyInfo.email || "暂无" }}</td>
                        <td>官网</td>
                        <td>{{ companyInfo.website || "暂无" }}</td>
                      </tr>
                      <!-- <tr>
            <td>Moody’s</td>
            <td>{{data['commercial_info/Moody’s']||'暂无'}}</td>
            <td>income_structure</td>
            <td>{{data['commercial_info/income_structure']||'暂无'}}</td>
            <td>{{"S&P"}}</td>
            <td>{{data['commercial_info/S&P']||'暂无'}}</td>
            </tr>-->
                      <tr>
                        <td>所属行业</td>
                        <td colspan="3" style="text-align: left; word-break: break-all">
                          {{ companyInfo.industry || "暂无" }}
                        </td>
                        <td>地址</td>
                        <td>{{ companyInfo.office_addr || "暂无" }}</td>
                      </tr>
                      <tr>
                        <td>经营范围</td>
                        <td colspan="5" style="text-align: left; word-break: break-all">
                          {{ companyInfo.domain || "暂无" }}
                        </td>
                      </tr>
                      <tr>
                        <td>描述</td>
                        <td colspan="5" style="text-align: left; word-break: break-all">
                          {{ companyInfo.company_profile || "暂无" }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </el-collapse-item>
              <el-collapse-item title="股东信息" name="2">
                <el-table class="shareHolderTable" :data="detailTrTopVal" style="width: 100%"
                  header-cell-style="background-color: #C4E1F0;color: #333" v-loading="shareholderLoading">
                  <el-table-column prop="name" label="股东名称">
                  </el-table-column>
                  <el-table-column prop="attribute" label="股东性质">
                  </el-table-column>
                  <el-table-column prop="share_ratio" label="持股比例">
                  </el-table-column>
                  <el-table-column prop="share_num" label="持股数量">
                  </el-table-column>
                  <el-table-column prop="share_condition_num" label="持有有限条件的股份数量">
                  </el-table-column>
                </el-table>
              </el-collapse-item>
              <el-collapse-item title="投资公司信息" name="3">
                <el-table class="shareHolderTable" :data="subCompanyList" style="width: 100%"
                  header-cell-style="background-color: #C4E1F0;color: #333" v-loading="subCompanyLoading">
                  <el-table-column prop="sub_company_name" label="投资公司名称">
                  </el-table-column>
                  <el-table-column prop="relation" label="关系">
                  </el-table-column>
                  <el-table-column prop="sub_company_type" label="投资公司类型">
                  </el-table-column>
                  <el-table-column prop="shareholding_ratio" label="持股比例">
                  </el-table-column>
                  <el-table-column prop="founded_time" label="成立时间">
                  </el-table-column>
                  <el-table-column prop="address" label="地址">
                  </el-table-column>
                </el-table>
                <div style="
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  margin: 15px 0 15px 0;
                ">
                  <el-pagination background layout="prev, pager, next" :current-page.sync="subCompanyParams.page_num"
                    :total="subCompanyTotal" :page-size="subCompanyParams.page_size" :page-count="5"
                    @current-change="handlePageChangeSubCompany">
                  </el-pagination>
                </div>
              </el-collapse-item>
            </el-collapse>
            <div style="margin-top: 15px; margin-bottom: 15px; width: 35%" v-if="showRadar">
              <p style="
                font-size: 16px;
                font-weight: bold;
                color: #333333;
                margin-left: 15px;
                margin-left: ;
              ">
                ▪ 评分明细
              </p>
              <div style="
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                overflow: hidden;
              ">
                <p style="
                  text-align: center;
                  font-weight: bold;
                  font-size: 16px;
                  color: #2c6c8f;
                ">
                  综合评分：{{ radarTotal }}
                </p>
                <score-radar :apiData="radarData"></score-radar>
              </div>
            </div>
          </div>
          <div style="width: 98%;margin-left: 1%;" v-if="activeTab == '产业布局'">
            <p class="subTitle">投资公司布局</p>
            <OverviewMapHori :mapData="regionData" :pointColor="yellowPointColor" :dataType="'投资公司'" style="width: 100%"
              @handleClickCicle="handleClickCicle"></OverviewMapHori>
            <p class="subTitle">投资公司和技术标签</p>
            <EnterpriseSubsidiary v-loading="companyLabelLoading" :EnterpriseSubsidiaryData="EnterpriseSubsidiaryData">
            </EnterpriseSubsidiary>
            <div style="
              width: 100%;
              display: flex;
              justify-content: center;
              margin: 15px 0 15px 0;
            ">
              <el-pagination background layout="prev, pager, next" :current-page.sync="companyLabelParams.page"
                :total="subLabelTotal" :page-size="companyLabelParams.page_size" :page-count="5"
                @current-change="handlePageChangeSubLabel">
              </el-pagination>
            </div>
            <p class="subTitle">项目布局</p>
            <OverviewMapHori :mapData="investmentRegionData" :pointColor="greenPointColor" :dataType="'项目'"
              style="width: 100%" @handleClickCicle="handleClickCicleProject"></OverviewMapHori>
            <p class="subTitle">项目相关新闻</p>
            <NewsListsShow :newsList="projectNews" :loading="projectNewsLoading"
              style="margin-left: 20px; margin-top: 20px; min-height: 150px" v-loading="projectNewsLoading">
            </NewsListsShow>
          </div>
          <el-collapse v-if="activeTab == '产业链条'" class="collapseBox" v-model="activeName">
            <el-collapse-item title="▪ 产业标签" name="1" v-if="tagList.length != 0 ||
              productList.length != 0 ||
              industryPaquList.length != 0
              ">
              <TagList :tagList="allTagList" v-if="env == 'pro' && allTagList" :cancel_pointer="true"
                :tagType="'primary'">
              </TagList>
              <TagList :tagList="tagList" v-if="env != 'pro' && tagList" :cancel_pointer="true" :tagType="'primary'">
              </TagList>
              <TagList :tagList="productList" v-if="env != 'pro' && productList" :cancel_pointer="true"
                :tagType="'success'"></TagList>
              <TagList :tagList="industryPaquList" :cancel_pointer="true" v-if="env != 'pro' && industryPaquList"
                :tagType="'warning'"></TagList>
            </el-collapse-item>
            <el-collapse-item title="▪ 产业节点" name="2">
              <DomainTagList :tagList="domainTagList" :tagType="'primary'" @clickTag="handleClickTag"></DomainTagList>
            </el-collapse-item>
            <el-collapse-item title="▪ 产业上下游公司" name="3">
              <!-- <link-echarts
              v-loading="linkLinkLoading"
              :apiDataGraphChart="chartDataLink"
              :shortName="chartLinkShortName"
              :onlySub="onlySub"
            ></link-echarts> -->
              <el-tabs class="searchType" v-model="companyLinkParams.relation_type" @tab-click="hanldeClickRelationTab">
                <el-tab-pane label="上游公司" name="1"></el-tab-pane>
                <el-tab-pane label="下游公司" name="2"></el-tab-pane>
              </el-tabs>
              <el-table class="shareHolderTable" :data="companyLinkList" style="width: 100%"
                header-cell-style="background-color: #C4E1F0;color: #333" v-loading="companyLinkLoading">
                <el-table-column prop="full_name" label="公司名称">
                </el-table-column>
                <el-table-column prop="industry" label="产业标签">
                </el-table-column>
                <el-table-column prop="area" label="所属地区">
                </el-table-column>
                <el-table-column prop="founded_time" label="成立时间">
                </el-table-column>
              </el-table>
              <div style="
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  margin: 15px 0 15px 0;
                ">
                <el-pagination background layout="prev, pager, next" :current-page.sync="companyLinkParams.page"
                  :total="companyLinkTotal" :page-size="companyLinkParams.page_size" :page-count="5"
                  @current-change="handlePageChangeCompanyLink">
                </el-pagination>
              </div>
            </el-collapse-item>
            <el-collapse-item title="▪ 上下游产业" name="4">
              <el-tabs class="searchType" v-model="linkUpOrDown" @tab-click="hanldeClickProductLink">
                <el-tab-pane label="上游产业" name="1"></el-tab-pane>
                <el-tab-pane label="下游产业" name="2"></el-tab-pane>
              </el-tabs>
              <ProductLinkEcharts v-loading="linkLinkLoading" :apiDataGraphChart="chartDataLink"
                :shortName="chartLinkShortName" :onlySub="onlySub" :linkType="linkUpOrDown"></ProductLinkEcharts>
            </el-collapse-item>
          </el-collapse>
          <el-collapse class="collapseBox" v-model="activeName" v-if="activeTab == '财务收入'">
            <el-collapse-item title="▪ 财务信息" name="3">
              <div class="bombBox-content_v2">
                <financial-echarts ref="financialEcharts" :apiDataLineChart="chartDataFinancial"></financial-echarts>
              </div>
            </el-collapse-item>
            <el-collapse-item title="▪ 营收构成" name="4">
              <div class="bombBox-content_v2">
                <!-- <product-echarts
                ref="productEcharts"
                :apiDataLineChart="chartDataProduct"
                :apiYearList="chartProductYearList"
              ></product-echarts> -->
                <ProductBarEcharts ref="productEcharts" :apiDataLineChart="chartDataProduct"
                  :apiYearList="chartProductYearList"></ProductBarEcharts>
              </div>
            </el-collapse-item>
          </el-collapse>
          <div class="companyNewsBox" v-show="activeTab == '企业舆情'">
            <!-- <LinkGraph
            v-loading="newsGraphLoading"
            :apiGraphData="newsRelateProduct"
            :type="'新闻'"
            :newslinkSelected="newslinkSelected"
            @clickProductNews="handleClickProductNews"
            @clickParentCompanyNews="handleClickParentCompanyNews"
            @clickSubCompanyNews="handleClickSubCompanyNews"
            @loadSubCompany="handleLoadSubCompany"
            @changeSelected="handleChangeSelected"
            @clickSubCompanyProduct="handleClickSubCompanyProduct"
            style="width: 50%; height: 80vh"
          ></LinkGraph> -->
            <div class="filterArea">
              <FilterBoxSpec ref="filterTotal" :total="polarityTypeTotal" :btnList="polarityTypeList"
                @clickBtn="handleClickBtnPolarity"></FilterBoxSpec>
              <FilterBox ref="filterEvent" :name="'事件类型'" :btnList="eventTypeList" :is_limit="false"
                @clickBtn="handleClickBtnEventType"></FilterBox>
              <FilterBox ref="filterSub" :name="'投资公司'" :btnList="subCompanyNewsCountList" :is_limit="true"
                @clickBtn="handleClickBtnSubCompany"></FilterBox>
              <FilterBox ref="filterProduct" :name="'产品'" :btnList="productNewsCountList" :is_limit="true"
                @clickBtn="handleClickBtnProduct"></FilterBox>
              <!-- <div class="filterBox-row">
              <div class="filterBox-left">
                <div class="filterBox-item" style="margin-left: 0">全部  <span class="filterBox-num">10000</span></div>
              </div>
              <div class="filterBox-item">正面  <span class="filterBox-num">10000</span></div>
              <div class="filterBox-item">负面  <span class="filterBox-num">10000</span></div>
            </div> -->
              <!-- <div class="filterBox-row" style="margin-top: 25px">
              <div class="filterBox-left">
                事件类型
              </div>
              <div class="filterBox-item">不限  <span class="filterBox-num">10000</span></div>
              <div class="filterBox-item">经营  <span class="filterBox-num">10000</span></div>
              <div class="filterBox-item">正面  <span class="filterBox-num">10000</span></div>
              <div class="filterBox-item">正面  <span class="filterBox-num">10000</span></div>
              <div class="filterBox-item">正面  <span class="filterBox-num">10000</span></div>
              <div class="filterBox-item">正面  <span class="filterBox-num">10000</span></div>
            </div> -->
            </div>
            <div class="newsListBox" style="width: 98%;margin-left: 1%">
              <!-- <NewsList
              v-loading="newsLoading"
              :apiDataNewsList="newsListCompany"
              :newsShowLine="newsShowLine"
              :hasKeyword="false"
              :page="companyNewsParams.page"
              :page_size="companyNewsParams.page_size"
              :total="news_total"
              @changePage="handleChangeNewsPage"
            ></NewsList> -->
              <NewsListTwo style="margin-top: 20px;min-height: 200px;" v-loading="newsLoading"
                :apiDataNewsList="newsListCompany" :isEventRisk="companyNewsParams.news_type == '司法公告'"
                :newsShowLine="newsShowLine" :hasKeyword="false" :showRadio="(filter_type == '产品相关') & (env != 'pro')"
                :page="companyNewsParams.page" :page_size="companyNewsParams.page_size" :total="news_total"
                :newsLoading="newsLoading" @changePage="handleChangeNewsPage"></NewsListTwo>
              <!-- <div class="showMore" @click="showMoreNews">
            <p>{{ showMoreText }}</p>
          </div> -->
            </div>
          </div>
          <div class="companyJob" v-if="activeTab == '企业招聘'">
            <!-- <LinkGraph
            :apiGraphData="postRelateProduct"
            :newslinkSelected="newslinkSelected"
            @clickProductPost="handleClickProductPost"
            @clickSubCompanyPost="handleClickSubCompanyPost"
            @loadSubCompany="handleLoadSubCompany"
            @changeSelected="handleChangeSelected"
            @clickSubCompanyProduct="handleClickSubCompanyProduct"
            :type="'岗位'"
            style="width: 50%; height: 460px"
            v-loading="postGraphLoading"
          ></LinkGraph> -->
            <!-- <div style="width: 50%"> -->
            <IndustryTalentEcharts style="margin-left: 10px; width: 90%;" :apiDataLineChart="talentEchartsData"
              v-loading="talentEchartLoading"></IndustryTalentEcharts>
            <PostCountBar :apiDataBarChart="postCount" style="width: 90%; margin-left: 20px"
              @clickPostRank="handleClickPostRank" v-loading="postCountLoading"></PostCountBar>
            <!-- </div> -->
          </div>
          <FilterBox ref="filterPost" :name="''" :btnList="postCountList" :is_limit="true" @clickBtn="handleClickBtnPost"
            :leftWidth="0.01" v-loading="postGraphLoading" v-if="activeTab == '企业招聘'"></FilterBox>
          <PostTable v-if="activeTab == '企业招聘'" v-loading="postLoading" :apiData="post_list" :post_type="'按公司'"
            :page="postParams.page" :page_size="postParams.page_size" :post_total="post_total"
            @changePostPage="handlePostPageChange">
          </PostTable>
          <div v-if="activeTab == '专利布局'">
            <p class="subTitle">专利发布趋势图</p>
            <PatentTrendEcharts style="width: 88%; height: 300px; margin-left: 6%" :apiDataLineChart="patentTrendData">
            </PatentTrendEcharts>
            <p class="subTitle" style="margin-top: 45px">技术领域河流图</p>
            <EchartRiver :riverDataProp="riverData" v-loading="riverLoading" :riverLoading="riverLoading"
              style="min-height: 200px"></EchartRiver>
            <!-- <LinkGraph
              :apiGraphData="patentRelateProduct"
              :newslinkSelected="newslinkSelected"
              @clickProductPatent="handleClickProductPatent"
              @clickParentCompanyPatent="handleClickParentCompanyPatent"
              @clickSubCompanyPatent="handleClickSubCompanyPatent"
              @loadSubCompany="handleLoadSubCompany"
              @changeSelected="handleChangeSelected"
              @clickSubCompanyProduct="handleClickSubCompanyProduct"
              :type="'专利'"
              style="width: 96%; height: 460px; margin-top: 10px; margin-left: 2%"
            ></LinkGraph> -->
            <!-- <NewsKeywordCloud
              style="width: 96%; height: 40vh; margin-left: 2%"
              :apiDataLineChart="wordCloudDataPatent"
            ></NewsKeywordCloud> -->
            <p class="subTitle" style="margin-top: 45px">
              专利技术
              <span style="color: rgb(64, 149, 229);">{{
                company_patent_total
              }}</span>
            </p>
            <FilterBox ref="filterPatentSub" :name="'投资公司'" :btnList="subCompanyCountList" :is_limit="true"
              @clickBtn="handleClickBtnSubPatent" :leftWidth="4" style="margin-left: 2%"></FilterBox>
            <FilterBox ref="filterPatentProduct" :name="'产品'" :btnList="patentProductCountList" :is_limit="true"
              @clickBtn="handleClickBtnProductPatent" :leftWidth="4" style="margin-left: 2%"></FilterBox>
            <PatentCardList style="width: 92%; margin-left: 4%;" v-loading="patentLoading" :apiData="patent_listRival"
              :company_id="patentParams.company_id" :showModal="false" @showMore="showMorePatent" :total="patent_total"
              :page="patentParams.page" :page_size="patentParams.page_size" @changePage="handleChangePatentPage">
            </PatentCardList>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "../utils/eventBus.js";
import cookie from "@/utils/common.js";
import api from "@/api/api";
import LinkEcharts from "@/components/LinkEcharts.vue";
import FinancialEcharts from "@/components/FinancialEcharts.vue";
import ProductEcharts from "@/components/ProductEcharts.vue";
import ProductBarEcharts from "@/components/ProductBarEcharts.vue";
import PatentEcharts from "@/components/PatentEcharts.vue";
import KeywordCloud from "@/components/KeywordCloud.vue";
import PatentList from "@/components/PatentList.vue";
import ScoreRadar from "@/components/ScoreRadar.vue";
import NewsList from "@/components/NewsList.vue";
import NewsListTwo from "@/components/NewsListTwo.vue";
import IndustryTalentEcharts from "@/components/IndustryTalentEcharts.vue";
import PostCountBar from "@/components/PostCountBar.vue";
import PointBar from "@/components/PointBar.vue";
import NewsKeywordCloud from "@/components/NewsKeywordCloud.vue";
import PatentCard from "@/components/PatentCard.vue";
import PatentTrendEcharts from "@/components/PatentTrendEcharts.vue";
import LinkGraph from "@/components/LinkGraph.vue";
import TagList from "@/components/TagList.vue";
import DomainTagList from "@/components/DomainTagList.vue";
import PatentCardList from "@/components/PatentCardList.vue";
import PostTable from "@/components/PostTable.vue";
import Star from "@/components/Star.vue";
import OverviewMapHori from "@/components/OverviewMapHori.vue";
import EnterpriseSubsidiary from "@/components/EnterpriseSubsidiary.vue";
import ProductLinkEcharts from "@/components/ProductLinkEcharts.vue";
import FilterBox from "@/components/FilterBox.vue";
import FilterBoxSpec from "@/components/FilterBoxSpec.vue";
import EchartRiver from "@/components/EchartRiver.vue";
import NewsListsShow from "@/components/NewsListsShow.vue";

export default {
  components: {
    LinkEcharts,
    FinancialEcharts,
    ProductEcharts,
    ProductBarEcharts,
    PatentEcharts,
    KeywordCloud,
    PatentList,
    ScoreRadar,
    NewsList,
    NewsListTwo,
    IndustryTalentEcharts,
    PostCountBar,
    PointBar,
    NewsKeywordCloud,
    PatentCard,
    PatentTrendEcharts,
    LinkGraph,
    TagList,
    PatentCardList,
    PostTable,
    Star,
    DomainTagList,
    OverviewMapHori,
    EnterpriseSubsidiary,
    ProductLinkEcharts,
    FilterBox,
    FilterBoxSpec,
    EchartRiver,
    NewsListsShow,
  },
  data() {
    return {
      subLabelTotal: 0,
      companyLabelLoading: false,
      companyLabelParams: {
        page: 1,
        page_size: 10,
        company_id: "",
        province: "",
      },
      projectNews: [],
      projectNewsLoading: false,
      projectNewsParams: {
        page: 1,
        page_size: 10,
        province: "",
        company_id: "",
      },
      postCountLoading: false,
      talentEchartLoading: false,
      company_patent_total: 0,
      currentNewsType: 0, // 当前新闻类型，一共有三种，0：常规企业新闻、1：司法公告、2：企业正负面新闻、3：产品相关新闻
      companyNewsTotalParams: {
        page: 1,
        page_size: 10,
        polarity: "",
        company: "",
        account: "",
      },
      riverData: {},
      riverLoading: false,
      currentEventType: "不限",
      postGraphLoading: false,
      polarityTypeTotal: 0,
      polarityTypeList: [],
      subCompanyNewsCountList: [],
      productNewsCountList: [],
      patentProductCountList: [],
      subCompanyCountList: [],
      postCountList: [],
      eventTypeList: [],
      linkUpOrDown: "1",
      companyLinkList: [],
      companyLinkLoading: false,
      companyLinkTotal: 0,
      companyLinkParams: {
        page: 1,
        page_size: 10,
        company_id: "",
        relation_type: "1",
      },
      investmentRegionData: [],
      yellowPointColor: "#FFFF33",
      greenPointColor: "#3CEC41",
      EnterpriseSubsidiaryData: [],
      regionData: [],
      subCompanyLoading: false,
      shareholderLoading: false,
      subCompanyParams: {
        page_num: 1,
        page_size: 10,
      },
      baseActiveNames: ["1", "2", "3"],
      baseInfoLoading: false,
      company_guogao_id: "",
      linkLinkLoading: false,
      newsGraphLoading: false,
      domainTagList: [],
      env: "",
      parentCompanyId: "",
      parentCompany: "",
      post_total: 0,
      news_total: 0,
      patent_total: 0,
      patentLoading: false,
      postLoading: false,
      newsLoading: false,
      newsShowLine: 0,
      showInfo: false,
      loading: false,
      showRadar: false,
      newsList: [],
      allTagList: [], // 所有标签去重合并(供正式环境使用)
      tagList: [],
      productList: [],
      industryPaquList: [],
      chartDataLink: {},
      chartLinkShortName: "",
      onlySub: false,
      chartDataFinancial: [],
      chartDataProduct: {},
      chartProductYearList: [],
      detailTrTopVal: [],
      patentEchartsData: [],
      wordCloudData2: {},
      patentListData: [],
      collapseActiveNames: [],
      compNameForLogo: "",
      companyInfo: {
        star: false,
      },
      radarTotal: 0,
      radarData: [],
      talentEchartsData: {},
      postCount: {},
      post_list: [],
      wordCloudDataPatent: [],
      patent_listRival: [],
      patentTrendData: {},
      newsRelateProduct: {},
      postRelateProduct: {},
      patentRelateProduct: {},
      newslinkSelected: {
        产品: true,
        公司: true,
        投资公司: false,
        投资公司产品: false,
      },
      linkSelectedPost: {},
      linkSelectedPatent: {},
      activeTab: "基本信息",
      showMoreText: "加载更多",
      activeName: ["1", "2", "3", "4", "5", "6"],
      chartDataFinancialReal: [],
      companyNewsParams: {
        page: 1,
        page_size: 10,
        account: "",
        // company_id: '',
        company: "",
        domain_id: "",
        industry: "",
        news_type: "",
      },
      newsListCompany: [],
      patentParams: {
        account: "",
        industry: "",
        domain_id: "",
        company_guogao_id: "",
        page: 1,
        page_size: 5,
        filter_type: "相关公司专利",
        inventor: "",
        company_id: "",
        applicant_id: "",
      },
      postParams: {
        account: "",
        industry: "",
        company_id: "",
        page: 1,
        page_size: 10,
        industry_node: "",
        post_classification: "",
        filter_type: "相关公司岗位",
      },
    };
  },
  watch: {
    $route: {
      handler(newVal, oldVal) {
        let row = JSON.parse(newVal.query.row);
        console.log("route.row", row);
        this.selectCompany(row);
      },
    },
  },
  methods: {
    // 投资公司和技术标签分页器
    handlePageChangeSubLabel(val) {
      this.companyLabelParams.page = val;
      this.getSubCompanyLabel();
    },
    // 获取项目布局相关新闻
    getProjectNews() {
      this.projectNewsLoading = true;
      api
        .getProjectNews(this.projectNewsParams)
        .then((res) => {
          this.projectNews = res.data;
          this.projectNewsLoading = false;
        })
        .catch((err) => {
          this.projectNewsLoading = false;
        });
    },
    handleClickCicle(region) {
      this.companyLabelParams.province = region;
      this.companyLabelParams.page = 1;
      this.getSubCompanyLabel();
    },
    // 响应项目布局点击省份
    handleClickCicleProject(region) {
      this.projectNewsParams.page = 1;
      this.projectNewsParams.province = region;
      this.getProjectNews();
    },
    handleClickBtnPost(btn) {
      this.postParams.page = 1;
      this.postParams.industry_node = btn.domain_id;
      this.postParams.filter_type = "相关产品岗位";
      this.getPostInfo();
    },
    // 响应点击招聘柱状图
    handleClickPostRank(val) {
      console.log("响应点击招聘柱状图", val);
      this.postParams.post_classification = val;
      this.postParams.page = 1;
      this.postParams.company_id = this.companyInfo.id;
      this.getPostInfo();
    },
    getCountCompanyPatent(company_id) {
      let params = {
        company_id: company_id,
      };
      api.getCountCompanyPatent(params).then((res) => {
        this.company_patent_total = res.data;
      });
    },
    handleClickBtnProductPatent(btn) {
      this.$refs.filterPatentSub.resetIndex();
      this.patentParams.page = 1;
      this.patentParams.domain_id = btn.domain_id;
      this.patentParams.company_id = this.companyInfo.id;
      this.patentParams.filter_type = "相关产品专利";
      this.getIndustryPatentRival();
    },
    // 点击相应投资公司
    handleClickBtnSubPatent(btn) {
      this.$refs.filterPatentProduct.resetIndex();
      this.patentParams.company_id = btn.company_id;
      this.patentParams.domain_id = "";
      this.patentParams.page = 1;
      this.patentParams.industry = "";
      this.getIndustryPatentRival();
    },
    // 获取公司专利河流图
    getCompanyPatentRiver() {
      let params = {
        company_id: this.companyInfo.id,
      };
      this.riverLoading = true;
      api.getCompanyPatentRiver(params).then((res) => {
        let river_tmp = JSON.parse(JSON.stringify(res.data));
        this.riverData = {};
        this.$nextTick(() => {
          this.riverData = river_tmp;
          this.riverLoading = false;
          // this.showRiver = true
        });
      });
    },
    // 监听选择产业链
    listenIndustryDrop() {
      EventBus.$on("industry_drop", (data) => {
        if (this.$route.path == "/company_info") {
          this.$router.push("/category_graph");
        }
      });
    },
    // 监听选择产业树
    listenTreeClick() {
      EventBus.$on("tree_click", (data) => {
        if (this.$route.path == "/company_info") {
          this.$router.push("/category_graph");
        }
      });
    },
    // 排序
    sortBy(property) {
      return function (value1, value2) {
        let a = value1[property];
        let b = value2[property];
        return a < b ? 1 : a > b ? -1 : 0;
      };
    },
    handleClickBtnProduct(btn) {
      this.currentNewsType = 3;
      this.companyNewsParams.page = 1;
      this.companyNewsParams.company = this.companyInfo.short_name;
      this.companyNewsParams.domain_id = btn.domain_id;
      this.getCompanyNewsByProduct();
    },
    // 获取公司信息
    getCompanyNewsByShortName() {
      this.companyNewsTotalParams.company = this.companyNewsParams.company;
      this.companyNewsTotalParams.account = cookie.getCookie("account");
      this.newsLoading = true;
      api.getCompanyNewsByShortName(this.companyNewsTotalParams).then((res) => {
        this.newsListCompany = res.data;
        this.news_total = res.count;
        this.newsLoading = false;
      });
    },
    handleClickBtnPolarity(btn) {
      this.currentNewsType = 2;
      this.$refs.filterEvent.resetIndex();
      this.$refs.filterSub.resetIndex();
      this.$refs.filterProduct.resetIndex();
      if (btn.name == "全部") {
        this.companyNewsTotalParams.polarity = "";
      } else if (btn.name == "正面") {
        this.companyNewsTotalParams.polarity = 1;
      } else if (btn.name == "负面") {
        this.companyNewsTotalParams.polarity = 0;
      }
      this.getCompanyNewsByShortName();
      // this.companyNewsParams.company = this.companyInfo.short_name
      // this.companyNewsParams.page = 1
      // this.getCompanyNews()
    },
    // 企业舆情点击筛选(事件类型)
    handleClickBtnEventType(btn) {
      this.companyNewsParams.company = this.companyInfo.short_name;
      this.companyNewsParams.news_type = btn.name;
      this.companyNewsParams.page = 1;
      this.$refs.filterTotal.resetIndex();
      this.$refs.filterSub.resetIndex();
      this.$refs.filterProduct.resetIndex();
      if (btn.name != "司法公告") {
        this.currentNewsType = 0;
        this.getCompanyNews();
      } else {
        this.currentNewsType = 1;
        this.getEventRisk();
      }
    },
    // 获取司法公告
    getEventRisk() {
      this.newsLoading = true;
      api.getEventRisk(this.companyNewsParams).then((res) => {
        this.newsListCompany = res.data;
        this.news_total = res.count;
        this.newsLoading = false;
      });
    },
    // 企业舆情点击筛选(投资公司)
    handleClickBtnSubCompany(btn) {
      this.$refs.filterEvent.resetIndex();
      this.$refs.filterTotal.resetIndex();
      this.$refs.filterProduct.resetIndex();
      this.companyNewsParams.news_type = "";
      this.companyNewsParams.company = btn.short_name;
      this.companyNewsParams.page = 1;
      this.getCompanyNews();
    },
    // 获取投资公司新闻统计
    getSubCompanyNewsCount(company_id) {
      let params = {
        company_id: company_id,
      };
      api.getSubCompanyNewsCount(params).then((res) => {
        this.subCompanyNewsCountList = res.data;
        // console.log('@@@', this.subCompanyNewsCountList);
        this.subCompanyNewsCountList.unshift({
          name: "不限",
          count: "",
        });
      });
    },
    // 获取新闻统计
    getNewsCount(company_id) {
      let params = {
        company_id: company_id,
      };
      api.getNewsCount(params).then((res) => {
        this.polarityTypeList = res.polarity_count_list;
        // this.polarityTypeTotal = res.total_count; // X.X 后端返回的total_count 有误 不是正面和负面舆情的和
        this.polarityTypeTotal = res.polarity_count_list[0].count + res.polarity_count_list[1].count;
        this.eventTypeList = res.event_type_count_list;
        this.eventTypeList.unshift({
          name: "不限",
          count: "",
        });
      });
    },
    // 切换上游公司、下游公司
    hanldeClickRelationTab(val) {
      this.companyLinkParams.page = 1;
      this.getCompanyLink(this.companyInfo.id);
    },
    //
    handlePageChangeCompanyLink(val) {
      this.companyLinkParams.page = val;
      this.getCompanyLink(this.companyInfo.id);
    },
    // 获取上下游公司
    getCompanyLink(company_id) {
      this.companyLinkParams.company_id = company_id;
      this.companyLinkLoading = true;
      api.getCompanyLink(this.companyLinkParams).then((res) => {
        this.companyLinkList = res.data;
        this.companyLinkTotal = res.total;
        this.companyLinkLoading = false;
      });
    },
    // 获取项目布局
    getCompanyInvestmentRegion(company_id) {
      let params = {
        company_id: company_id,
      };
      api.getCompanyInvestmentRegion(params).then((res) => {
        this.investmentRegionData = res.data;
        this.investmentRegionData.forEach((element) => {
          element.region_name = element.region_name
            .replace("省", "")
            .replace("市", "")
            .replace("自治区", "");
        });
      });
    },
    // 获取投资公司标签
    getSubCompanyLabel() {
      this.companyLabelLoading = true;
      api
        .getSubCompanyLabel(this.companyLabelParams)
        .then((res) => {
          this.EnterpriseSubsidiaryData = [];
          this.subLabelTotal = res.count;
          res.data.forEach((element) => {
            console.log("patent_coarse", element.patent_coarse);
            this.EnterpriseSubsidiaryData.push({
              companyName: element.full_name,
              // 'labelList':[["空调柜机", 0.800745016169307], ["冷风扇", 0.7929233697654426], ["新风装置", 0.7871331204715707], ["空调器领域", 0.7848806746080478], ["滚刷机构", 0.7780885423062752], ["气泡水机", 0.776571902247329], ["排气阀组件", 0.7598990953375087], ["空调领域", 0.7434023087314257], ["智能家电领域", 0.7250368414589217], ["洗衣系统", 0.7156985549961111]],
              labelList:
                element.patent_coarse.length > 0
                  ? JSON.parse(element.patent_coarse)
                  : [],
            });
          });
          this.companyLabelLoading = false;
        })
        .catch((err) => {
          this.companyLabelLoading = false;
        });
    },
    // 获取投资公司区域分布
    getSubCompanyRegion(company_id) {
      let params = {
        company_id: company_id,
      };
      api.getSubCompanyRegion(params).then((res) => {
        this.regionData = res.data;
        this.regionData.forEach((element) => {
          element.region_name = element.region_name
            .replace("省", "")
            .replace("市", "")
            .replace("自治区", "");
        });
      });
    },
    // 获取投资公司翻页
    handlePageChangeSubCompany(val) {
      this.subCompanyParams.page_num = val;
      this.getSubCompany(this.companyInfo.id);
    },
    // 获取投资公司
    getSubCompany(company_id) {
      let params = {
        company_id: company_id,
        page_num: this.subCompanyParams.page_num,
        page_size: this.subCompanyParams.page_size,
        source_id: 2, // X.X 投资公司统一请求source_id为2的数据
      };
      this.subCompanyLoading = true;
      api
        .getSubCompany(params)
        .then((res) => {
          this.subCompanyTotal = res.total;
          this.subCompanyList = res.data;
          this.subCompanyLoading = false;
        })
        .catch((err) => {
          this.subCompanyLoading = false;
        });
    },
    // 监听选择企业查看企业图谱
    listenSelectCompanyInfo() {
      EventBus.$on("selectCompanyInfo", (data) => {
        let row = JSON.parse(data);
        this.selectCompany(row);
      });
    },
    // 响应取消收藏
    handleCancelStar(val) {
      this.$emit("cancelStar", 1);
      // utils.objArrRemove(this.apiNewsList, "id", val)
    },
    // 响应点击岗位产品
    handleClickProductPost(val) {
      this.postParams.page = 1;
      this.postParams.industry_node = val;
      this.postParams.filter_type = "相关产品岗位";
      this.postParams.company_id = this.parentCompanyId;
      this.getPostInfo();
    },
    // 响应点击岗位投资公司
    handleClickSubCompanyPost(val) {
      this.postParams.page = 1;
      this.postParams.industry_node = "";
      this.postParams.company_id = val;
      this.postParams.filter_type = "相关公司岗位";
      this.getPostInfo();
    },
    // 响应点击专利的产品
    handleClickProductPatent(val) {
      this.patentParams.page = 1;
      this.patentParams.domain_id = val;
      this.patentParams.company_id = this.parentCompanyId;
      this.patentParams.filter_type = "相关产品专利";
      this.getIndustryPatentRival();
    },
    // 响应点击新闻的产品
    handleClickProductNews(val) {
      this.companyNewsParams.domain_id = val;
      this.companyNewsParams.page = 1;
      this.companyNewsParams.company = this.parentCompany;
      this.getCompanyNewsByProduct();
    },
    // 获取企业新闻
    getCompanyNews() {
      this.newsLoading = true;
      api
        .getCompanyNews(this.companyNewsParams)
        .then((res) => {
          this.newsListCompany = res.data;
          this.news_total = res.count;
          this.newsLoading = false;
        })
        .catch((err) => {
          this.newsLoading = false;
        });
    },
    // 响应图谱子组件点击母公司（舆情）
    handleClickParentCompanyNews(val) {
      this.companyNewsParams.domain_id = "";
      this.companyNewsParams.company = val;
      this.companyNewsParams.page = 1;
      this.getCompanyNews();
    },
    // 响应图谱子组件点击母公司（专利）
    handleClickParentCompanyPatent(val) {
      this.patentParams.domain_id = "";
      this.patentParams.page = 1;
      this.patentParams.company_id = val;
      this.getIndustryPatentRival();
    },
    handleLoadSubCompany() {
      let filter_type = "news";
      if (this.activeTab == "企业舆情") {
        filter_type = "news";
        this.newsRelateProduct.sub_company_list.forEach((s) => {
          this.getSubCompanyRelatedProducts(s.id, filter_type);
        });
      } else if (this.activeTab == "企业招聘") {
        filter_type = "post";
        this.postRelateProduct.sub_company_list.forEach((s) => {
          this.getSubCompanyRelatedProducts(s.id, filter_type);
        });
      } else if (this.activeTab == "专利布局") {
        filter_type = "patent";
        this.patentRelateProduct.sub_company_list.forEach((s) => {
          this.getSubCompanyRelatedProducts(s.id, filter_type);
        });
      }
    },
    handleChangeSelected(val) {
      this.newslinkSelected = val;
    },
    // 响应点击投资公司产品
    handleClickSubCompanyProduct(val) {
      // this.companyNewsParams.company = "";
      if (this.activeTab == "企业舆情") {
        this.companyNewsParams.domain_id = val.split("_")[2];
        this.newsRelateProduct.sub_company_list.forEach((s) => {
          if (s.id == val.split("_")[1]) {
            this.companyNewsParams.company = s.short_name;
          }
        });
        // this.companyNewsParams.company = val.split("_")[1]
        this.companyNewsParams.page = 1;
        this.companyNewsParams.industry = "";
        this.newsListCompany = [];
        this.getCompanyNewsByProduct();
      } else if (this.activeTab == "专利布局") {
        this.patentParams.domain_id = val.split("_")[2];
        this.patentParams.company_id = val.split("_")[1];
        this.patentParams.filter_type = "相关产品专利";
        this.patentParams.page = 1;
        this.patentParams.industry = "";
        this.patent_listRival = [];
        this.getIndustryPatentRival();
      }
    },
    handleShowSubCompany(val) {
      let company_id = val;
      let filter_type = "news";
      if (this.activeTab == "企业舆情") {
        filter_type = "news";
        this.newsRelateProduct.sub_company_list.forEach((s) => {
          s.type = "sub_company";
          s.name = s.short_name;
          this.newsRelateProduct.nodes.push(s);
          let edge = {
            edge_name: "关联",
            from_id: val,
            to_id: s.id,
            weight: 0,
          };
          this.newsRelateProduct.edges.push(edge);
        });
      } else if (this.activeTab == "企业招聘") {
        filter_type = "post";
        this.postRelateProduct.sub_company_list.forEach((s) => {
          s.type = "sub_company";
          s.name = s.short_name;
          this.postRelateProduct.nodes.push(s);
          let edge = {
            edge_name: "关联",
            from_id: val,
            to_id: s.id,
            weight: 0,
          };
          this.postRelateProduct.edges.push(edge);
        });
      } else if (this.activeTab == "专利布局") {
        filter_type = "patent";
        this.patentRelateProduct.sub_company_list.forEach((s) => {
          s.type = "sub_company";
          s.name = s.short_name;
          this.patentRelateProduct.nodes.push(s);
          let edge = {
            edge_name: "关联",
            from_id: val,
            to_id: s.id,
            weight: 0,
          };
          this.patentRelateProduct.edges.push(edge);
        });
      }
      // this.getSubCompanyRelatedProducts(company_id, filter_type)
    },
    handleShowSubCompanyProduct(val) {
      let company_id = val;
      let filter_type = "news";
      if (this.activeTab == "企业舆情") {
        filter_type = "news";
      } else if (this.activeTab == "企业招聘") {
        filter_type = "post";
      } else if (this.activeTab == "专利布局") {
        filter_type = "patent";
      }
      this.patentParams.company_id = val;
      // this.getSubCompanyRelatedProducts(company_id, filter_type)
    },
    handlePostPageChange(val) {
      this.postParams.page = val;
      this.getPostInfo();
    },
    handleChangeNewsPage(val) {
      if (this.currentNewsType == 0) {
        this.companyNewsParams.page = val;
        this.getCompanyNews();
      } else if (this.currentNewsType == 1) {
        this.companyNewsParams.page = val;
        this.getEventRisk();
      } else if (this.currentNewsType == 2) {
        this.companyNewsTotalParams.page = val;
        this.companyNewsParams.page = val; // X.X 修复负面新闻分页跳转bug
        this.getCompanyNewsByShortName();
      } else if (this.currentNewsType == 3) {
        this.companyNewsParams.page = val;
        this.getCompanyNewsByProduct();
      }

      // this.getCompanyNewsByProduct();
      // this.getCompanyNews();
    },
    handleChangePatentPage(val) {
      this.patentParams.page = val;
      this.getIndustryPatentRival();
    },
    // 监听公司详情-专利布局图谱点击方块
    listenDetailPatentProduct() {
      EventBus.$on("detail_patent_product", (data) => {
        this.patentParams.page = 1;
        this.patentParams.filter_type = "相关产品专利";
        this.patentParams.domain_id = data;
        this.patent_listRival = [];
        // this.getPatentList()
        this.getIndustryPatentRival();
      });
    },
    // 监听公司详情-企业招聘图谱点击方块
    listenDetailPostProduct() {
      EventBus.$on("detail_post_product", (data) => {
        this.postParams.industry_node = data;
        this.postParams.page = 1;
        this.postParams.filter_type = "相关产品岗位";
        this.post_list = [];
        this.getPostInfo();
      });
    },
    // 监听公司详情-企业舆情图谱点击方块
    listenDetailNewsProduct() {
      EventBus.$on("detail_news_product", (data) => {
        this.companyNewsParams.domain_id = data;
        this.companyNewsParams.page = 1;
        this.newsListCompany = [];
        this.getCompanyNewsByProduct();
      });
    },
    // 监听公司详情-企业舆情图谱点击投资公司
    handleClickSubCompanyNews(val) {
      this.companyNewsParams.company = val;
      this.companyNewsParams.domain_id = "";
      this.companyNewsParams.page = 1;
      this.companyNewsParams.industry = "";
      this.newsListCompany = [];
      // this.getCompanyNewsByProduct();
      this.getCompanyNews();
    },
    // listenDetailNewsSubCompany() {
    //   EventBus.$on("detail_news_sub_company", (data) => {
    //     this.companyNewsParams.company = data;
    //     this.companyNewsParams.domain_id = ""
    //     this.companyNewsParams.page = 1;
    //     this.companyNewsParams.industry = "ele_car"
    //     this.newsListCompany = [];
    //     this.getCompanyNewsByProduct();
    //   });
    // },
    // 监听公司详情-专利布局图谱点击投资公司
    handleClickSubCompanyPatent(val) {
      this.patentParams.company_id = val;
      this.patentParams.domain_id = "";
      this.patentParams.page = 1;
      this.patentParams.industry = "";
      this.patent_listRival = [];
      this.getIndustryPatentRival();
    },
    // listenDetailPatentSubCompany() {
    //   EventBus.$on("detail_patent_sub_company", data => {
    //     this.patentParams.company_id = data
    //     this.patentParams.domain_id = ""
    //     this.patentParams.page = 1
    //     this.patentParams.industry = "ele_car"
    //     this.patent_listRival = []
    //     this.getIndustryPatentRival()
    //   })
    // },
    // 响应父组件事件（点击公司）
    selectCompany(val) {
      this.subCompanyParams.page_num = 1;
      this.companyLinkParams.relation_type = "1";
      this.linkUpOrDown = "1";
      this.companyLinkParams.page = 1;
      this.activeTab = "基本信息";
      this.newslinkSelected = {
        产品: true,
        公司: true,
        投资公司: false,
        投资公司产品: false,
      };
      this.getCompanyInfo(val);
    },
    // 获取公司详情信息
    async getCompanyInfo(row) {
      // this.loading = true;
      console.log("!!!!!!!getCompanyInfo", row);
      let params = {
        full_name: row.full_name,
        account: cookie.getCookie("account"),
      };
      // this.radarData = [
      //   row.scale,
      //   row.technology,
      //   row.customer,
      //   row.brand,
      //   // row.risk + 100
      //   row.vitality,
      // ];
      this.patentParams.page = 1;
      this.companyLabelParams.page = 1;
      // this.radarTotal = row.comprehensive;
      this.patentListData = [];
      this.patent_listRival = [];
      this.baseInfoLoading = true;
      console.log("COMPANY_INFO_PARAMS::::", params);
      await api.getCompanyInfo(params).then((res) => {
        console.log("COMPANY_INFO_RES::::", res);
        let info = res.data[0];
        this.companyInfo.entity_name = info.full_name;
        this.companyInfo.short_name = info.short_name;
        this.companyInfo.telephone = info.telephone;
        this.companyInfo.website = info.website;
        this.companyInfo.email = info.email;
        this.companyInfo.office_addr = info.address;
        this.companyInfo.description = "";
        this.companyInfo.label = "";
        this.companyInfo.board_id = info.board_id;
        this.companyInfo.board = info.board;
        this.companyInfo.legal_representative = info.representative;
        if (info.enterprises_type && info.enterprises_type.match(/\（\d+）|\(\d+\)/)) {
          this.companyInfo.company_type = "";
        } else {
          this.companyInfo.company_type = info.enterprises_type;
        }
        this.companyInfo.founded_date = info.establishment;
        this.companyInfo.regis_capital = info.registered_capital;
        this.companyInfo.regis_code = info.taxpayer_num;
        this.companyInfo.headquater = info.region;
        this.companyInfo.employees = info.insured_num;
        this.companyInfo.name_en = info.english_name;
        this.companyInfo.listed = info.is_on_market;
        if (info.business_scope && info.business_scope.match(/\（\d+）|\(\d+\)/)) {
          this.companyInfo.domain = "";
        } else {
          this.companyInfo.domain = info.business_scope;
        }
        this.companyInfo.industry = info.industry;
        this.companyInfo.company_profile = info.company_profile;
        this.companyInfo.id = info.id;
        this.companyInfo.star = info.star;
        this.projectNewsParams.company_id = info.id;
        this.compNameForLogo = info.short_name
          ? info.short_name.substring(0, 4)
          : info.full_name.substring(3, 7);
        this.chartLinkShortName = info.short_name
          ? info.short_name
          : info.full_name;
        this.companyNewsParams.account = cookie.getCookie("account");
        this.companyNewsParams.page = 1;
        this.companyNewsParams.company = info.short_name;
        this.parentCompany = info.short_name; // 记录当前母公司，供点击新闻图谱的产品作为参数
        this.newsListCompany = [];
        this.post_list = [];
        // this.getCompanyNews();
        // this.getCompanyNewsByProduct();
        this.showInfo = true;
        this.baseInfoLoading = false;
      });
      // let company_id = await api.getCompanyId(params).then((res) => {
      //   console.log("获取公司company_id", res.data);
      //   return res.data;
      // });
      this.company_guogao_id = await api
        .getCompanyGuogaoId(params)
        .then((res) => {
          return res.data;
        });
      this.patentParams.company_id = this.companyInfo.id;
      // this.parentCompanyId = this.companyInfo.id // 记录当前母公司，供点击专利图谱的产品作为参数
      // this.getProductTag(this.companyInfo.id);
      // this.getCompanyDomainTags(this.companyInfo.id)
      // this.getLink(this.companyInfo.id);
      // this.getFinancialAndProductInfo(this.companyInfo.id);
      this.getHolder(this.companyInfo.id);
      this.getSubCompany(this.companyInfo.id);
      this.getCountCompanyPatent(this.companyInfo.id);
      // this.getPatentCountAndKeyword(this.companyInfo.id);
      // this.getPostMonthRank(this.companyInfo.id);
      // this.getPostCount(this.companyInfo.id);
      // this.getIndustryPatentKeyword(company_guogao_id);
      // this.getIndustryPatentRival();
      // this.getCompanyPatentTrend(this.companyInfo.id);
      // this.postParams.company_id = this.companyInfo.id;
      // this.getPostInfo();
      // this.getPatentList();
      // this.getCompanyRelatedProducts(this.companyInfo.id, "patent");
      // this.getCompanyRelatedProducts(this.companyInfo.id, "post");
      // this.getCompanyRelatedProducts(this.companyInfo.id, "news");
    },
    // 对象数组根据属性去重
    unique(arr, attr) {
      const res = new Map();
      return arr.filter((a) => !res.has(a[attr]) && res.set(a[attr], 1));
    },
    // 获取企业产业节点标签
    getCompanyDomainTags(company_id) {
      let params = {
        company_id: company_id,
      };
      api.getCompanyDomainTags(params).then((res) => {
        this.domainTagList = res.data;
      });
    },
    // 获取产业节点的根节点
    getDomainNodeRoot(node) {
      let params = {
        node_id: node.id,
      };
      api.getDomainNodeRoot(params).then((res) => {
        sessionStorage.setItem("index_industry", JSON.stringify(res.data));
        this.$store.commit("changeIndexIndustry", res.data.name);
        EventBus.$emit("index_industry", res.data);
        let node_link_list = JSON.stringify(res.data.node_link_list);
        if (this.$route.path == "/category_graph") {
          EventBus.$emit("company_detail_to_category_graph", node_link_list);
        } else {
          // this.$router.push(
          //   `/category_graph?from=company_detail_hori&query=${node_link_list}`
          // );
          let data = {
            root_id: res.domain_id,
            id: node.id,
            name: node.name,
            root_name: res.name,
          };
          sessionStorage.setItem("subscribe_node", JSON.stringify(data));
          this.$router.push("/category_graph?from=personal_subscribe");
        }
      });
    },
    // 响应点击产业节点标签
    handleClickTag(val) {
      console.log("响应点击产业节点标签", val);
      sessionStorage.setItem("is_cy", true);
      this.showInfo = false;
      this.getDomainNodeRoot(val);
    },
    // 获取企业舆情（按照产品）
    getCompanyNewsByProduct() {
      this.newsLoading = true;
      api
        .getCompanyNewsByProduct(this.companyNewsParams)
        .then((res) => {
          console.log("产业舆情", res);
          this.newsListCompany = res.data;
          this.news_total = res.count;
          this.newsLoading = false;
        })
        .catch((err) => {
          this.newsLoading = false;
          console.error("加载新闻失败", err);
        });
    },
    // 获取公司产业标签
    getProductTag(company_id) {
      this.tagList = [];
      this.productList = [];
      this.industryPaquList = [];
      let params = {
        company_id: company_id,
      };
      api
        .getProductTag(params)
        .then((res) => {
          console.log("产业标签", res);
          // this.productList = res.data.product;
          // 去除为空的标签
          res.data.product.forEach((i) => {
            if (i != "") {
              this.productList.push(i);
            }
          });

          // 正式环境
          // res.data.product_list.forEach(i => {
          //   this.industryPaquList.push(i)
          // })
          // console.log("indexof");
          // this.industryPaquList = [...new Set(this.industryPaquList)];
          // 正式环境

          if (res.data.product_detail) {
            this.tagList = res.data.product_detail.split("；");
            this.tagList.pop();
            this.tagList = [...new Set(this.tagList)];
          }
          if (res.data.industry_paqu) {
            this.industryPaquList = res.data.industry_paqu.split("；");
            this.industryPaquList.pop();
          }
          if (this.env == "pro") {
            this.productList.push.apply(this.productList, this.tagList);
            this.productList.push.apply(
              this.productList,
              this.industryPaquList
            );
            this.allTagList = this.productList;
            console.log("allTagList", this.allTagList);
          }
        })
        .catch((err) => {
          // this.$message.error("服务器出错，请重试!")
        });
    },
    // 获取产业链上下游
    async getLink(company_id) {
      let params = {
        company_id: company_id,
      };
      this.linkLinkLoading = true;
      await api
        .getLink(params)
        .then((res) => {
          console.log("LINK", res);
          this.chartDataLink = res.data;
          if (
            res.data.chain_company_link.length == 0 &&
            res.data.chain_product_link.length == 0
          ) {
            this.onlySub = true;
          } else {
            this.onlySub = false;
          }
          // this.chartLinkShortName = this.short_name;
          // this.linkLoading = false;
          this.linkLinkLoading = false;
        })
        .catch((err) => {
          this.linkLinkLoading = false;
          // this.linkLoading = false;
          // this.$message.error("服务器出错，请重试!")
        });
      // this.setGraphChart();
    },
    // 获取财务信息和产品信息
    async getFinancialAndProductInfo(company_id) {
      // this.companyInfoVisible = true;
      let params = {
        company_id: company_id,
      };
      // this.loading = true;
      await api
        .getFinancialInfo(params)
        .then((res) => {
          console.log("financial", res);
          this.chartDataFinancial = res.data;
          console.log("res=>chartDataFinancial", this.chartDataFinancial);
        })
        .catch((err) => {
          // this.loading = false;
          console.log(err);
        });
      await api.getProductInfo(params).then((res) => {
        console.log("产品信息", res);
        this.chartDataProduct = res.data;
        this.chartProductYearList = res.year_list;
      });
      this.$nextTick(() => {
        // this.loading = false;
      });
    },
    // 获取股东信息
    getHolder(company_id) {
      let params = {
        company_id: company_id,
        page: 1,
        page_size: 5,
      };
      // this.pdfLoading = true;
      this.shareholderLoading = true;
      api
        .getPdfAnnual(params)
        .then((res) => {
          console.log("年度报告", res);
          this.newsList = res.data;
          console.log("最新的newsList", this.newsList[0]);
          this.detailTrTopVal = [];
          // 载入最新股东信息
          // this.detailTrTopVal[0] = [
          //   "股东名称",
          //   "股东性质",
          //   "持股比例",
          //   "持股数量",
          //   "持有有限条件的股份数量",
          // ];
          if (this.newsList.length != 0 && this.newsList[0].shareholder) {
            this.newsList[0].shareholder.forEach((i) => {
              // this.detailTrTopVal.push([
              //   i.name,
              //   i.attribute,
              //   i.share_ratio,
              //   i.share_num,
              //   i.share_condition_num,
              // ]);
              this.detailTrTopVal.push({
                name: i.name,
                attribute: i.attribute,
                share_ratio: i.share_ratio,
                share_num: i.share_num,
                share_condition_num: i.share_condition_num,
              });
            });
          }
          console.log("detailTrTopVal", this.detailTrTopVal);
          // this.pageTotal = this.newsList.length;
          // this.pageTotal = res.total;
          // this.pdfLoading = false;
          this.shareholderLoading = false;
        })
        .catch((err) => {
          this.pdfLoading = false;
          this.shareholderLoading = false;
          this.$message({
            message: err,
            type: "error",
          });
        });
    },
    // 获取专利统计和关键词云
    getPatentCountAndKeyword(company_id) {
      let params = {
        company_id: company_id,
      };
      // this.patentLoading = true
      api
        .getCompanyBaseInfo(params)
        .then((res) => {
          console.log("专利", res);
          this.patentEchartsData = res.data[0].year_count_list;
          this.wordCloudData2 = res.data[0].patent_keywords;
          // this.patentLoading = false
        })
        .catch((err) => {
          // this.patentLoading = false
        });
    },
    // 获取招聘月度排行
    getPostMonthRank(company_id) {
      let params = {
        industry: "",
        filter_type: "相关公司岗位",
        // industry_node: node,
        industry_node: "",
        company_id: company_id,
      };
      this.talentEchartLoading = true;
      api
        .getPostMonthRank(params)
        .then((res) => {
          // console.log("月度排行", res)
          this.talentEchartsData = res.data;
          this.talentEchartLoading = false;
        })
        .catch((err) => {
          this.talentEchartLoading = false;
        });
    },
    // 获取招聘岗位统计
    getPostCount(company_id) {
      this.postCount.legend = [];
      this.postCount.data = [];
      this.postCount.name = {};
      let params = {
        industry: "",
        // industry_node: node,
        industry_node: "",
        company_id: company_id,
        filter_type: "相关公司岗位",
      };
      this.postCountLoading = true;
      api
        .getPostCount(params)
        .then((res) => {
          console.log("岗位统计", res);
          res.data.forEach((i) => {
            let legend_item =
              i.name.length > 4 ? i.name.substring(0, 4) + "..." : i.name;
            this.postCount.legend.push(legend_item);
            this.postCount.name[legend_item] = i.post_all;
            this.postCount.data.push({
              value: i.count,
            });
            this.postCountLoading = false;
          });
        })
        .catch((err) => {
          this.postCountLoading = false;
        });
    },
    // 获取产业专利关键词
    getIndustryPatentKeyword(company_guogao_id) {
      let params = {
        industry: this.patentParams.industry,
        domain_id: this.patentParams.domain_id,
        company_guogao_id: company_guogao_id,
        filter_type: this.patentParams.filter_type,
      };
      api
        .getIndustryPatentKeyword(params)
        .then((res) => {
          console.log("产业专利关键词", res);
          this.wordCloudDataPatent = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 获取产业专利
    getIndustryPatentRival() {
      // this.patent_list = []
      this.patentLoading = true;
      console.log("PATENTPARAMS::::", this.patentParams);
      api
        .getIndustryPatent(this.patentParams)
        .then((res) => {
          console.log("产业专利", res);
          this.patent_listRival = [];
          this.patent_listRival = res.data;
          this.patent_total = res.count;
          console.log("patent_list", this.patent_listRival);
          this.patentLoading = false;
        })
        .catch((err) => {
          this.patentLoading = false;
          console.log("err", err);
          // alert(err)
          if (err.toString().indexOf("timeout") != -1) {
            this.$message.error("专利加载超时，请重试！");
          }
        });
    },
    // 获取公司专利趋势
    getCompanyPatentTrend(company_id) {
      let params = {
        company_id: company_id,
      };
      api.getCompanyPatentTrend(params).then((res) => {
        console.log("获取公司专利趋势", res);
        this.patentTrendData = res.data;
      });
    },
    // 获取公司招聘信息
    getPostInfo() {
      this.postParams.account = cookie.getCookie("account");
      this.postLoading = true;
      api
        .getPostInfo(this.postParams)
        .then((res) => {
          console.log("获取公司招聘信息", res.data);
          // this.post_list = res.data;
          // res.data.forEach((i) => {
          //   this.post_list.push(i);
          // });
          this.post_list = res.data;
          this.post_total = res.count;
          this.postLoading = false;
          // this.post_total = res.count;
        })
        .catch((err) => {
          this.postLoading = false;
        });
    },
    // 获取专利列表
    getPatentList() {
      // let params = {
      //   company_id: company_id,
      //   page: 1,
      //   page_size: 10,
      // };
      // this.patentLoading = true;
      // this.patentParams.company_id = company_id
      api.getPatentList(this.patentParams).then((res) => {
        console.log("-------------专利列表", res);
        // this.patentListData = res.data;
        this.patentListTotal = res.total;
        res.data.forEach((i) => {
          this.patentListData.push(i);
        });
        console.log("patentListData", this.patentListData);
        // this.patentLoading = false;
      });
    },
    // 获取投资公司相关产品关系图
    getSubCompanyRelatedProducts(company_id, filter_type) {
      let params = {
        company_id: company_id,
        filter_type: filter_type,
        is_sub_company: 1,
      };
      console.log("filter_typexxxxx", filter_type);
      api.getCompanyRelatedProducts(params).then((res) => {
        console.log("获取投资公司相关产品关系图", res);
        if (filter_type == "news") {
          res.data.nodes.forEach((n) => {
            if (n.type != "company") {
              n.type = "sub_node";
              n.id = n.id.replace("n_", `s_${company_id}_`);
              // n.name = " " + n.name
              n.name = company_id.toString() + "_" + n.name;
              this.newsRelateProduct.nodes.push(n);
              let edge = {
                edge_name: "投资公司关联",
                from_id: company_id,
                to_id: n.id,
                weight: n.weight,
              };
              this.newsRelateProduct.edges.push(edge);
            }
          });
        } else if (filter_type == "patent") {
          console.log("渲染专利投资公司");
          // this.patentRelateProduct = res.data;
          res.data.nodes.forEach((n) => {
            if (n.type != "company") {
              n.type = "sub_node";
              n.id = n.id.replace("n_", `s_${company_id}_`);
              // n.name = " " + n.name
              n.name = company_id.toString() + "_" + n.name;
              this.patentRelateProduct.nodes.push(n);
              let edge = {
                edge_name: "投资公司关联",
                from_id: company_id,
                to_id: n.id,
                weight: n.weight,
              };
              this.patentRelateProduct.edges.push(edge);
            }
          });
        } else if (filter_type == "post") {
          // this.postRelateProduct = res.data;
          res.data.nodes.forEach((n) => {
            if (n.type != "company") {
              n.type = "sub_node";
              n.id = n.id.replace("n_", `s_${company_id}_`);
              // n.name = " " + n.name
              n.name = company_id.toString() + "_" + n.name;
              this.postRelateProduct.nodes.push(n);
              let edge = {
                edge_name: "投资公司关联",
                from_id: company_id,
                to_id: n.id,
                weight: n.weight,
              };
              this.postRelateProduct.edges.push(edge);
            }
          });
        }
      });
    },
    // 获取舆情、招聘、专利相关产品关系图
    getCompanyRelatedProducts(company_id, filter_type) {
      this.productNewsCountList = [];
      this.patentProductCountList = [];
      this.subCompanyCountList = [];
      this.postCountList = [];
      let params = {
        company_id: company_id,
        filter_type: filter_type,
        is_sub_company: 0,
      };
      if (filter_type == "news") {
        this.newsGraphLoading = true;
      }
      if (filter_type == "post") {
        this.postGraphLoading = true;
      }

      api.getCompanyRelatedProducts(params).then((res) => {
        console.log("获取获取舆情、招聘、专利相关产品关系图", res);
        res.data.sub_company_list.forEach((s) => {
          s.type = "sub_company";
          s.name = s.short_name;
          res.data.nodes.push(s);
          let edge = {
            edge_name: "关联",
            from_id: res.data.nodes[0].id,
            to_id: s.id,
            weight: 0,
          };
          res.data.edges.push(edge);
        });
        if (filter_type == "news") {
          this.newsRelateProduct = res.data;
          res.data.nodes.forEach((i) => {
            if (i.type == "node") {
              this.productNewsCountList.push({
                name: i.name,
                count: i.weight,
                domain_id: i.id.replace("n_", ""),
              });
            }
          });
          this.productNewsCountList = this.productNewsCountList.sort(
            this.sortBy("count")
          );
          this.productNewsCountList.unshift({
            name: "不限",
            count: "",
            domain_id: "",
          });
          this.newsGraphLoading = false;
          console.log("newsRelateProduct", this.newsRelateProduct);
        } else if (filter_type == "patent") {
          this.patentRelateProduct = res.data;
          res.data.nodes.forEach((i) => {
            if (i.type == "node") {
              this.patentProductCountList.push({
                name: i.name,
                count: i.weight,
                domain_id: i.id.replace("n_", ""),
              });
            }
          });
          this.patentProductCountList = this.patentProductCountList.sort(
            this.sortBy("count")
          );
          this.patentProductCountList.unshift({
            name: "不限",
            count: "",
            domain_id: "",
          });
          res.data.sub_company_list.forEach((i) => {
            this.subCompanyCountList.push({
              name: i.full_name,
              count: i.weight,
              company_id: i.id,
            });
          });
          this.subCompanyCountList = this.subCompanyCountList.sort(
            this.sortBy("count")
          );
          this.subCompanyCountList.unshift({
            name: "不限",
            count: "",
            company_id: company_id, // X.X 修改点击不限查找公司不对应问题，原因：不限按钮没有company_id
          });
        } else if (filter_type == "post") {
          this.postRelateProduct = res.data;
          res.data.nodes.forEach((i) => {
            if (i.type == "node") {
              this.postCountList.push({
                name: i.name,
                count: i.weight,
                domain_id: i.id.replace("n_", ""),
              });
            }
          });
          this.postCountList = this.postCountList.sort(this.sortBy("count"));
          this.postCountList.unshift({
            name: "全部岗位",
            count: "",
            domain_id: "",
          });
          this.postGraphLoading = false;
        }
      });
    },
    // 加载更多新闻
    showMoreNews() {
      // this.$emit("showMoreNews", 1);
      this.companyNewsParams.page += 1;
      this.getCompanyNews();
      // this.getCompanyNewsByProduct();
    },
    // 加载更多岗位
    showMorePost() {
      // this.$emit("showMorePost", 1);
      this.postParams.page += 1;
      this.getPostInfo();
    },
    async handleClickTab(val) {
      console.log("handleClickTab", val);
      console.log("activeTab", this.activeTab);
      this.newslinkSelected = {
        产品: true,
        公司: true,
        投资公司: false,
        投资公司产品: false,
      };
      if (val == "企业舆情") {
        // this.newsGraphLoading = true
        this.polarityTypeList = [];
        this.eventTypeList = [];
        this.subCompanyNewsCountList = [];
        this.productNewsCountList = [];
        this.$refs.filterTotal.resetIndex();
        this.$refs.filterEvent.resetIndex();
        this.$refs.filterSub.resetIndex();
        this.$refs.filterProduct.resetIndex();
        this.getCompanyNews();
        this.getCompanyRelatedProducts(this.companyInfo.id, "news");
        this.getNewsCount(this.companyInfo.id);
        this.getSubCompanyNewsCount(this.companyInfo.id);
      } else if (val == "产业链条") {
        this.linkUpOrDown = "1";
        this.getProductTag(this.companyInfo.id);
        this.getCompanyDomainTags(this.companyInfo.id);
        this.getLink(this.companyInfo.id);
        this.getCompanyLink(this.companyInfo.id);
        let tmp = this.chartDataLink;
        this.chartDataLink = {};
        this.$nextTick(() => {
          this.chartDataLink = tmp;
        });
        console.log("handleClickTab--------------2");
      } else if (val == "财务收入") {
        this.getFinancialAndProductInfo(this.companyInfo.id);

        let tmp1 = JSON.parse(JSON.stringify(this.chartDataFinancial));
        let tmp2 = this.chartDataProduct;
        this.chartDataFinancial = [];
        this.chartDataProduct = {};
        this.$nextTick(() => {
          this.chartDataFinancial = JSON.parse(JSON.stringify(tmp1));
          this.chartDataProduct = tmp2;
          console.log("chartDataFinancial--------", this.chartDataFinancial);
        });
      } else if (val == "企业招聘") {
        this.postParams.company_id = this.companyInfo.id;
        this.getPostCount(this.companyInfo.id);
        this.getCompanyRelatedProducts(this.companyInfo.id, "post");
        this.getPostMonthRank(this.companyInfo.id);
        this.getPostCount(this.companyInfo.id);
        this.getPostInfo();
        let tmp1 = this.talentEchartsData;
        let tmp2 = JSON.parse(JSON.stringify(this.postCount));
        console.log("postCount", this.postCount);
        let tmp3 = this.postRelateProduct;
        this.talentEchartsData = {};
        this.postCount = {};
        this.postRelateProduct = {};
        this.$nextTick(() => {
          this.talentEchartsData = tmp1;
          this.postCount = tmp2;
          this.postRelateProduct = tmp3;
        });
      } else if (val == "专利布局") {
        this.patentParams.company_id = this.companyInfo.id;
        this.getCompanyPatentTrend(this.companyInfo.id);
        this.getCompanyRelatedProducts(this.companyInfo.id, "patent");
        // this.getIndustryPatentKeyword(this.company_guogao_id);
        this.getCompanyPatentRiver();
        this.getIndustryPatentRival();
        let tmp = this.wordCloudDataPatent;
        let tmp2 = this.patentTrendData;
        let tmp3 = this.patentRelateProduct;
        this.wordCloudDataPatent = [];
        this.patentTrendData = [];
        this.patentRelateProduct = {};
        this.$nextTick(() => {
          this.wordCloudDataPatent = tmp;
          this.patentTrendData = tmp2;
          this.patentRelateProduct = tmp3;
        });
      } else if (val == "企业舆情") {
        let tmp = this.newsRelateProduct;
        this.newsRelateProduct = {};
        this.$nextTick(() => {
          this.newsRelateProduct = tmp;
        });
      } else if (val == "产业布局") {
        this.getSubCompanyRegion(this.companyInfo.id);
        this.companyLabelParams.company_id = this.companyInfo.id;
        this.getSubCompanyLabel();
        this.getCompanyInvestmentRegion(this.companyInfo.id);
        this.getProjectNews();
      }
    },
    // 加载更多(专利布局)
    showMorePatentRival() {
      // this.$emit("showMorePatentRival");
      this.patentParams.page += 1;
      this.getIndustryPatentRival();
    },
    // 加载更多专利
    showMorePatent() {
      // this.$emit("showMorePatent");
      this.patentParams.page += 1;
      console.log("page", this.patentParams.page);
      if (this.patentParams.page <= 100) {
        this.getPatentList();
      } else {
        // this.showMoreText = "已无更多"
      }
    },
    // 打开网页
    goto(web) {
      if (web.indexOf("http") == -1) {
        web = "http://" + web;
      }
      window.open(web);
    },
  },
  mounted() {
    let row = JSON.parse(this.$route.query.row);
    this.selectCompany(row);
    this.listenIndustryDrop();
    this.listenTreeClick();
    // this.listenSelectCompanyInfo()
  },
  beforeDestroy() {
    EventBus.$off("industryDrop");
    EventBus.$off("tree_click");
  },
};
</script>

<style lang="scss">
.companyDetailHori {
  &-star {
    margin-left: 5px;
  }

  // margin-top: 52px;
  .el-dialog__header {
    padding: 0;

    button {
      display: none;
    }
  }

  width: 100%;
  padding-bottom: 10px;

  // margin-left: 0.5%;
  // min-height: 80vh;
  // padding-bottom: 30px;
  // max-height: 90vh;
  // display: flex;
  // justify-content: space-between;
  // background-color: white;
  .productAnalyse-patent {
    .el-icon-close::before {
      color: white;
    }
  }

  .el-form-item {
    margin-bottom: 2px;
  }

  &-left,
  &-right {
    // width: 49.6%;
    width: 100%;
    // max-height: 85vh;
    margin-top: 5px;
    background-color: white;
    border-radius: 6px;
    -webkit-box-shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.07);
    padding-bottom: 5px;
  }

  &-right {
    margin-left: 5px;
  }

  .el-tabs__header {
    margin-bottom: 0;
    padding-left: 15px;
  }

  .contentFooter {
    // margin-bottom: 20px;
    height: 50px !important;
    margin-bottom: 2vh;
  }

  .showMore {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
    color: #333333;
    font-size: 14px;

    p {
      cursor: pointer;
      background-color: rgba(244, 244, 244, 1);
      padding: 5px 40px 5px 40px;

      &:hover {
        background-color: rgb(228, 226, 226);
      }
    }
  }

  .newsEchartsBox {
    background-color: white;
    display: flex;
    min-height: 250px;
    width: 100%;
    // margin-top: 4px;
    border-radius: 6px;
  }

  .selectType {
    position: absolute;
    z-index: 10;
    top: 24vh;
    left: 6.5vw;

    .img {
      display: inline-block;
      // object-fit: fill;
      width: 50px;
      height: 40px;
      margin: 5px;
      cursor: pointer;
      position: relative;
      overflow: hidden;

      div {
        width: 50px;
        position: absolute;

        // background-color: rgba($color: #000000, $alpha: 0.5);
        text-align: center;
        font-size: 14px;
        color: #fff;
        line-height: 40px;
      }

      .high {
        background-color: rgba($color: #000, $alpha: 0.5);
        color: #389bb7;
      }

      img {
        width: 50px;
        height: 40px;
        object-fit: cover;
      }
    }
  }

  tr:first-of-type {
    background-color: white;
  }

  #companyTable {
    margin-top: 20px;
  }

  .el-dialog__body {
    padding-top: 10px;
  }

  .radioBox {
    width: 100%;
    // position: absolute;
    // z-index: 1000;
    background-color: white;
    margin-top: 4px;
    padding-left: 15px;
    padding-top: 10px;
  }

  .nodeDesc {
    padding: 0 10px 0 10px;
    font-size: 15px;
  }

  .baseInfo {
    // table {
    width: 100%;
    color: #333333;
    margin-left: 0;

    // background-color: rgba($color: #000000, $alpha: 0.4);
    td {
      // border-bottom: solid rgba($color: white, $alpha: 0.3) 1px;
      padding: 10px;
      text-align: center;
      border-width: 1px 1pt 1pt;
      border-style: outset;
      //   padding: 2pt 5pt 2pt 5pt;
    }

    td:nth-child(odd) {
      width: 13%;
      background-color: rgba(64, 149, 229, 0.2);
    }

    td:nth-child(even) {
      width: 20%;
    }

    // }
  }

  .market {
    padding: 0 15px 0 15px;
    margin-top: 20px;

    // display: flex;
    // justify-content: space-between;
    &-top {
      width: 98%;

      .industryDesc {
        margin-top: 10px;
        text-indent: 35px;
        font-size: 15px;
        line-height: 22px;
        margin-bottom: 20px;
      }
    }

    &-bottom {
      width: 98%;
      display: flex;
      justify-content: space-between;
    }
  }

  .tech {
    width: 100%;

    &-top {
      display: flex;
    }
  }

  .industryLink_up {
    width: 30%;
    margin-bottom: 15px;
    margin-right: 5px;
    border-radius: 4px;
    border: 2px solid #8ac658;
    padding-top: 10px;

    &-item {
      border-radius: 4px;
      width: 94%;
      margin-left: 3%;
      border: 1px solid #8ac658;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 15px;
    }

    &-head,
    &-headSelected {
      background-color: #8ac658;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      // border: 1px solid #58b6e5;
      width: 100%;
      display: flex;
      justify-content: center;
      color: white;
    }

    &-headSelected {
      color: yellow;
    }

    &-subItem {
      display: flex;
      // justify-content: space-around;
      width: 100%;
      flex-wrap: wrap;

      &-noChild {
        border: 1px solid #8ac658;
        margin-top: 5px;
        margin-bottom: 5px;
        border-radius: 4px;
        // padding: 0px 3px 0px 3px;
        color: #333333;
        margin-right: 5px;
        margin-left: 5px;
      }
    }
  }

  .industryLink {
    width: 30%;
    margin-bottom: 15px;
    margin-right: 5px;
    border-radius: 4px;
    border: 2px solid #58b6e5;
    padding-top: 10px;

    &-item {
      border-radius: 4px;
      width: 94%;
      margin-left: 3%;
      border: 1px solid #58b6e5;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 15px;
    }

    &-head,
    &-headSelected {
      background-color: #58b6e5;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      // border: 1px solid #58b6e5;
      width: 100%;
      display: flex;
      justify-content: center;
      color: white;
    }

    &-headSelected {
      color: yellow;
    }

    &-subItem {
      display: flex;
      // justify-content: space-around;
      width: 100%;
      flex-wrap: wrap;

      &-head,
      &-headSelected {
        // background-color: #58b6e5;
        // border-top-right-radius: 4px;
        // border-top-left-radius: 4px;
        border-radius: 4px;
        padding: 0 2px 0 2px;
        border: 1px solid #58b6e5;
        width: 100%;
        display: flex;
        justify-content: center;
        color: #58b6e5;
      }

      &-headSelected {
        border-color: red;
        // border: 2px solid red;
        // color: red;
        background-color: red;
        color: white;
      }

      &-noChild {
        // border: 1px solid #58b6e5;
        margin-top: 5px;
        margin-bottom: 5px;
        border-radius: 4px;
        // padding: 0px 3px 0px 3px;
        color: #333333;
        margin-right: 5px;
        margin-left: 5px;
      }
    }
  }

  .industryLink_medium {
    width: 30%;
    margin-bottom: 15px;
    margin-right: 5px;
    border-radius: 4px;
    border: 2px solid #ff6600;
    padding-top: 10px;

    &-item {
      border-radius: 4px;
      width: 94%;
      margin-left: 3%;
      border: 1px solid #ff6600;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 15px;
    }

    &-head,
    &-headSelected {
      background-color: #ff6600;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      // border: 1px solid #ff6600;
      width: 100%;
      margin-left: -2;
      display: flex;
      justify-content: center;
      color: white;
    }

    &-headSelected {
      color: yellow;
    }

    &-subItem {
      display: flex;
      // justify-content: space-around;
      width: 100%;
      flex-wrap: wrap;

      &-head,
      &-headSelected {
        // background-color: #58b6e5;
        // border-top-right-radius: 4px;
        // border-top-left-radius: 4px;
        border-radius: 4px;
        padding: 0 2px 0 2px;
        border: 1px solid #ff6600;
        width: 100%;
        display: flex;
        justify-content: center;
        color: #ff6600;
      }

      &-headSelected {
        border-color: red;
        // border: 2px solid red;
        // color: red;
        background-color: red;
        color: white;
      }

      &-noChild {
        // border: 1px solid #ff6600;
        margin-top: 5px;
        margin-bottom: 5px;
        border-radius: 4px;
        // padding: 0px 3px 0px 3px;
        color: #333333;
        margin-right: 5px;
        margin-left: 5px;
      }
    }
  }

  .industryLink_down {
    width: 30%;
    margin-bottom: 15px;
    border-radius: 4px;
    border: 2px solid #f56c6c;
    padding-top: 10px;

    &-item {
      border-radius: 4px;
      width: 94%;
      margin-left: 3%;
      border: 1px solid #f56c6c;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 15px;
    }

    &-head,
    &-headSelected {
      background-color: #f56c6c;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      // border: 1px solid #f56c6c;
      width: 100%;
      display: flex;
      justify-content: center;
      color: white;
    }

    &-headSelected {
      color: yellow;
    }

    &-subItem {
      display: flex;
      // justify-content: space-around;
      width: 100%;
      flex-wrap: wrap;

      &-head {
        // background-color: #58b6e5;
        // border-top-right-radius: 4px;
        // border-top-left-radius: 4px;
        border-radius: 4px;
        padding: 0 2px 0 2px;
        border: 1px solid #f56c6c;
        width: 100%;
        display: flex;
        justify-content: center;
        color: #f56c6c;
      }

      &-noChild {
        // border: 1px solid #f56c6c;
        margin-top: 5px;
        margin-bottom: 5px;
        border-radius: 4px;
        // padding: 0px 3px 0px 3px;
        color: #333333;
        margin-right: 5px;
        margin-left: 5px;
      }
    }
  }

  .doubleArrow {
    width: 90%;
    margin-left: 5%;
  }

  .mores {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;

    &-text {
      cursor: pointer;
      background-color: #f4f4f4;
      padding: 5px 40px 5px 40px;

      &:hover {
        background-color: #d8d7d7;
      }
    }
  }

  .el-table--scrollable-x .el-table__body-wrapper {
    overflow: auto !important;
  }

  .el-table__body-wrapper::-webkit-scrollbar {
    width: 8px; // 横向滚动条
    height: 10px; // 纵向滚动条 必写
  }

  .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: rgb(205, 205, 221);
    border-radius: 5px;
  }

  .postDetail {
    &-post {
      font-size: 20px;
      font-weight: bold;
      color: black;
      display: flex;
      justify-content: space-between;
      padding-right: 15px;

      span {
        font-size: 18px;
        color: #5c7bd9;
      }
    }

    &-company {
      font-size: 16px;
      margin-top: 5px;
    }

    &-info {
      margin-top: 25px;
    }

    &-line {
      margin: 0 5px 0 5px;
      color: silver;
    }

    &-position_info {
      margin-top: 20px;
    }

    &-url {
      margin-top: 10px;
    }
  }

  .tagBox {
    &-tag {
      margin-right: 7px;
      margin-bottom: 5px;
      cursor: pointer;
    }
  }

  .detail {
    width: 100%;
    // max-width: 1200px;
    margin-top: 10px;

    &-mask {
      position: absolute;
      width: 100%;
      height: 100%;
      // background-color: rgba(91, 91, 94, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        color: #d3feff;
        font-size: 30px;
        font-weight: bold;
      }
    }

    .detailTop {
      display: flex;
      border-bottom: 1px solid rgb(239, 239, 239);
      // background-color: rgba($color: #000000, $alpha: 0.4);
      // border-bottom: solid silver 1px;
      // img {
      //   margin: 20px 10px 20px 25px;
      //   object-fit: contain;
      //   height: 80px;
      //   width: 80px;
      //   background: white;
      // }
    }

    .compLogo {
      margin: 20px 10px 20px 25px;
      height: 80px;
      width: 80px;
      // background-color: white;
      // border: 1px solid gray;
      background-color: #01a4d9;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        width: 80px;
        overflow: hidden;
        word-break: break-all;
        word-wrap: wrap;
        margin-left: 7px;
        text-align: center;
        color: white;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 8px;
      }
    }
  }

  .el-collapse-item__wrap {
    width: 95%;
    margin-left: 2.5%;
    padding-bottom: 20px;
  }

  .shareHolderTable {
    margin-top: 10px;
  }

  .subTitle {
    font-weight: bold;
    font-size: 16px;
    color: rgb(16, 16, 16);
    margin: 10px 0 20px 10px;
  }

  .filterArea {
    box-shadow: 0 1px 10px 0 gray;
    border: 1px solid rgb(239, 239, 239);
    font-size: 14px;
    width: 96%;
    margin-left: 2%;
    // height: 200px;
    margin-top: 20px;
    padding: 25px 15px 15px 15px;
  }

  .companyJob {
    display: flex;
    margin-bottom: 70px;
  }
}
</style>
