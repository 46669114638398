<template>

<div class='companyNews'>
        <div class='companyNewsPart'>
            <div class='chooseButton'>
                <div @click="changeLabel('企业相关事件')"  class='chooseLeftButton' ref='buttonOne'>
                    企业相关事件
                </div>
                <!-- <div @click="changeLabel('事件脉络')" class='chooseRightButton' ref='buttonTwo'>
                    事件脉络
                </div> -->
            </div>
            <div v-if="label_type == '企业相关事件'" class='companyNewsList' ref='companyNewsList'>
                <div class='newsList' v-loading='companyNewsLoad'>
                    <div class="block">
                        <Timeline>
                            <TimelineItem 
                            v-for="(activity, index) in activities"
                            :key="index"
                            :color="activity.polarity ==1 ?'green': '#D9001B' ">
                                <div class="time">
                                    <span>{{ activity.release_time | formatDate }}</span>
                                    <span> {{ activity.source }} </span>
                                    <span
                                        v-if="activity.polarity !== ''"
                                        :class="
                                        activity.polarity == 1
                                            ? 'newsTitle-polarity'
                                            : 'newsTitle-polarityBad'
                                        "
                                    >
                                        {{ activity.polarity == 1 ? "正面" : "负面" }}
                                    </span>
                                </div>
                                <p class="content" @click="toOriginNews(activity.url)">
                                    {{ activity.title }}</p>
                            </TimelineItem>
                        </Timeline>
                    </div>
                    <div
                        style="
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            margin: 15px 0 15px 0;
                        "
                        
                        >
                        <el-pagination
                            background
                            layout="prev, pager, next"
                            :current-page.sync="pageParams.page"
                            :total="pageParams.count"
                            :page-size="pageParams.page_size"
                            hide-on-single-page
                            pager-count=7
                            small='true'
                            
                            @current-change="handlePageChange"
                        >
                        </el-pagination>
                        </div>
                </div>
            </div>
            <div v-else-if="label_type == '事件脉络'" class='otherThings'>        
            </div>
            <div v-else class='else'></div>
            
        </div>
</div>   
</template>
<script>
import api from "@/api/api"
import cookie from "@/utils/common.js";
export default{
    data(){
        return{
            label_type:'企业相关事件',
            activities: [],
            pageParams:{
                count:0,
                page_size:10,
                page:1
            },
            companyNewsLoad: false,
            flag:1,
        };
    },      
    methods:{ 
        changeLabel(str){
            console.log('changeLabel')           
            this.companyNewsLoad=true
            this.label_type = str
            if (this.$store.state.companyNewsList != null){
                console.log('数据赋值')
                this.activities = this.$store.state.companyNewsList.data
                // console.log('store.act:',this.activities)
                // console.log('reset.act:',this.activities)
                this.pageParams.count = this.$store.state.companyNewsList.count
            }
            
            // this.$refs.companyNewsList.style = "overflow-y: scroll"
            
            // if (this.flag == 1){
            //     for (let item = 0; item < this.activities.length; item ++){
            //     this.activities[item].release_time = this.activities[item].release_time + ' ' + this.activities[item].source
            //     }
            //     this.flag = 0
            // }            
            
            // console.log('this.pageParams.count',this.pageParams.count)
            // console.log('this.activities',this.activities)
            // this.activities.release_time = this.activities.release_time + '    ' + this.activities.source            
            this.companyNewsLoad=false
            if (this.label_type == '企业相关事件'){
                console.log('qq')
                try{
                    this.$refs.buttonOne.style = "background-color:#409eff;color:#fff"               
                    this.$refs.buttonTwo.style = "background-color:#fff;color:#409eff"
                    console.log('qqstop')
                }
                catch(error){
                    console.log(error)
                }
                
            }else if (this.label_type == '事件脉络'){
                console.log('ss')
                try{
                    this.$refs.buttonTwo.style = "background-color:#409eff;color:#fff"
                    this.$refs.buttonOne.style = "background-color:#fff;color:#409eff"
                }
                catch(error){
                    console.log(error)
                }
            }
            try{
                console.log('tt')
                this.$refs.companyNewsList.style = "overflow-y: scroll;scrollbar-color: #999 transparent;"}
            catch(error){
                console.log('$refsError')
            }
                
        },
        // 分页器变化
        handlePageChange(val) {
            // this.flag = 1
            this.companyNewsLoad=true
            this.pageParams.page = val
            // console.log('val:',val,'ss',this.pageParams.page)
            console.log('short_nametwo:',this.$store.state.companyShortName)
            let params = {
              'page':this.pageParams.page,
              'page_size':10,
              'account':cookie.getCookie("account"),
              'company':this.$store.state.companyShortName,
              'domain_id':'',
              'industry':'',
              'news_type':'',
              'company_extract_type':'1',
          }
            api.getNewsByCompany(params).then((res) =>{
                this.$store.state.companyNewsList = res
                this.companyNewsLoad=false
            })
            // .then(()=>{
            //     this.changeLabel(this.label_type)
            // })
        },
        toOriginNews(url){
          window.open(url, "_blank");
        },
        changeHtml(activity){
            let htmlText = `<p
                    v-if="activity.polarity !== ''"
                    :class="
                      activity.polarity == 1
                        ? 'newsTitle-polarity'
                        : 'newsTitle-polarityBad'
                    "
                  >
                    {{ activity.polarity == 1 ? "正面" : "负面" }}
                  </p>`
            return htmlText
        }
    },
    computed:{
        listenCompanyNewsListData(){
            return this.$store.state.companyNewsList.data
        },
        listenCompanyShortNameData(){
            return this.$store.state.companyShortName
        }
    },
    watch:{
        listenCompanyNewsListData(newValue,old){              
            // console.log(newValue,'sssssss','old')
            // this.flag = 1
            this.changeLabel(this.label_type)
        },
        listenCompanyShortNameData(newValue,old){
            this.companyNewsLoad=true
            this.pageParams.page=1
            // this.flag = 1
        }
    }
}
</script>
<style lang='scss'>
.companyNews{
    // overflow-y:scroll;
    
    height:100%;
    min-width: 200px;
    border:1px solid #DCDFE6;
    border-top-left-radius:5px;
    border-top-right-radius:5px;
    // -webkit-box-shadow: inset 0 0 6px #c5c3c3;
    // margin-top:20px !important;
    .companyNewsPart{
    // margin-top:20px !important;;
    height:80vh;
    display: flex;
    flex-direction: column;
    // border:4px;
    .chooseButton{
        // flex:1.25;
        height:45.5px;
        display:flex;
        text-align:center;
        // display:float;
        // height:60px;
        // border:1px;
        .chooseLeftButton{
            flex:1;
            // float:left;
            display:block;
            line-height:45.5px;
            weight:100%;
            // height:100%;
            background-color:#409eff;
            border-top-left-radius:5px;
            color:#fff;
            border:1px;
            // border-top:1px;
            // border-left:1px;
            // border-bottom:0px;
        }
        .chooseRightButton{
            flex:1;
            // float:left;           
            display:block;
            line-height:45.5px;
            weight:100%;
            height:100%;
            background-color:#fff;
            border-top-right-radius:5px;
            color:#409eff;
            // border:1px solid;
            // border-top:1px;
            // border-right:1px;
            // border-bottom:0px;         
        }
    }
    .chooseButton:hover{
        cursor: pointer;
    }

    .companyNewsList{
        // overflow-y: scroll;
        // flex:14.75;
        margin-top:20px;
        padding-top:5px;
        padding-left:20px;
        .time{
                font-size: 14px;
                // font-weight: bold;
                color:#999;
                display: flex;
                justify-content: flex-start;
                span{
                    margin-right: 5px;
                }
            }
        .content:hover{
            cursor:pointer;
            
        }
        .content{
            margin-top: 10px;
        }       
    }
    .otherThings{
        flex:14.75;
        margin-top:20px;
        padding-left:20px; 
    }
    .else{
        flex:14.75;
        margin-top:20px;
        padding-left:20px;
    }
    }
    
    .newsTitle,
    .listedTitle {
      display: flex;
      justify-content: space-between;
      // padding-right: 10px;
      width: 95%;
      // overflow: hidden;
      // white-space: nowrap;
      // text-overflow:ellipsis;
      &-polarity,
      &-polarityBad {
        opacity: 0.9;
        height: 20px;
        min-width: 32px;
        margin-left: 10px;
        // margin-right: 10px;
        background-color: #E8FCC9;
        color: #70B603 ;
        font-size: 12px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &-polarityBad {
        background-color: #FEF0F0;
        color: #D9001B;
      }
    }
}
.companyNews::-webkit-scrollbar{
    display: none;
}

</style>