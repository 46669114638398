<template>
  <div class="filterBox-row">
    <div class="filterBox-left">
      <div :class="activeIndex == 0 ? 'filterBox-itemSelected' : 'filterBox-item'" style="margin-left: 0" @click="activeIndex = 0;handleClickBtn({name: '全部'})">
        全部 <span :class="activeIndex == 0 ? 'filterBox-numSelected' : 'filterBox-num'">{{ total }}</span>
      </div>
    </div>
    <div
      :class="
        activeIndex == index+1 ? 'filterBox-itemSelected' : 'filterBox-item'
      "
      v-for="(b, index) in btnList"
      :key="b.name"
      @click="activeIndex = index + 1;handleClickBtn(b)"
    >
      {{ b.name }}
      <span :class="activeIndex == index + 1 ? 'filterBox-numSelected' : 'filterBox-num'" v-if="b.count">{{ b.count }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "filterBox",
  props: {
    total: Number,
    btnList: Array,
  },
  data() {
    return {
      activeIndex: 0,
    };
  },
  methods: {
    resetIndex() {
      this.activeIndex = 0
    },
    handleClickBtn(b) {
      console.log("btn", b)
      this.$emit("clickBtn", b)
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.filterBox {
  &-row {
    display: flex;
    margin-bottom: 10px;
  }
  &-left {
    width: 112px;
    display: flex;
  }
  &-num, &-numSelected {
    color: rgb(64, 149, 229);
    margin-left: 10px;
  }
  &-numSelected {
    color: white;
  }
  &-item,
  &-itemSelected {
    cursor: pointer;
    padding: 2px 5px 2px 5px;
    border: 1px solid rgb(64, 149, 229);
    border-radius: 4px;
    margin-left: 10px;
    margin-bottom: 10px;
  }
  &-itemSelected {
    background-color: rgb(64, 149, 229);
    color: white;
  }
}
</style>
