<template>
  <div class="linkEcharts">
    <div class="graphContainer">
      <div class="graphChart" ref="graphChart"></div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "../utils/eventBus.js";
export default {
  name: "LinkEcharts",
  props: {
    apiDataGraphChart: Object,
    shortName: String,
    // 是否只拥有子公司
    onlySub: Boolean,
    linkType: Number, // 产业上下游标识，0：上；1：下
  },
  data() {
    return {
      graphChart: "",
      graphData: [],
      graphDataOrigin: [],
      graphLink: [],
    };
  },
  watch: {
    apiDataGraphChart: {
      async handler(newVal, oldVal) {
        newVal.chain_product_link = this.unique(
          newVal.chain_product_link,
          "chain_reciprocal_product_name"
        );
        // 生成节点
        let graphData = [];
        graphData.push({
          name: this.shortName,
          category: 0,
        });
        newVal.chain_product_link.forEach((i) => {
          let category = 1;
          if (i.relation == 1) {
            category = 1;
          } else {
            category = 2;
          }
          graphData.push({
            name: i.chain_reciprocal_product_name,
            category: category,
          });
        });
        graphData = this.unique(graphData, "name");
        // console.log("graphData", graphData);
        // 生成关系
        let graphLink = [];
        graphData.forEach((i) => {
          // 上游(→)
          if ([1, 3].indexOf(i.category) != -1) {
            graphLink.push({
              source: i.name,
              target: this.shortName,
              value: "",
            });
          } else {
            // 下游(←)
            graphLink.push({
              source: this.shortName,
              target: i.name,
              value: "",
            });
          }
        });
        // console.log("graphLink", graphLink);
        this.graphDataOrigin = await graphData;
        this.graphLink = await graphLink;
        this.graphData = this.graphDataOrigin.filter(
            (x) => x.category == 1 || x.name == this.shortName
          );
        this.$nextTick(() => {
          this.setGraphChart();
        });
        // setTimeout(() => {
        //   this.setGraphChart()
        // }, 500)
      },
    },
    linkType: {
      handler(newVal, oldVal) {
        if (newVal == "1") {
          this.graphData = this.graphDataOrigin.filter(
            (x) => x.category == 1 || x.name == this.shortName
          );
        } else {
          this.graphData = this.graphDataOrigin.filter(
            (x) => x.category == 2 || x.name == this.shortName
          );
        }
        console.log("graphData", this.graphData);
        this.$nextTick(() => {
          this.setGraphChart();
        });
      },
    },
  },
  methods: {
    // 对象数组根据属性去重
    unique(arr, attr) {
      const res = new Map();
      return arr.filter((a) => !res.has(a[attr]) && res.set(a[attr], 1));
    },
    // echart初始化
    setOriginGraphChart() {
      echarts4_7.init(this.$refs.graphChart).dispose();
      this.graphChart = echarts4_7.init(this.$refs.graphChart);
      this.graphChart.on("click", function (params) {
        if (params.data.category == 3 || params.data.category == 4) {
          console.log("params", params.data);
          EventBus.$emit("searchIndustryNews", params.data.name);
        }
      });
    },
    // 渲染echart
    setGraphChart() {
      let option = {
        legend: {
          x: "left",
          show: false,
          data: ["本公司", "上游产业", "下游产业"],
          selected: {
            子公司: this.onlySub,
          },
          textStyle: {
            fontSize: 13, //字体大小
            color: "#333333", //字体颜色
          },
        },
        animation: false,
        series: [
          {
            type: "graph",
            layout: "force",
            force: {
              layoutAnimation: false,
              repulsion: 1000,
            },
            // focusNodeAdjacency: false,
            //symbolSize: 65,
            // force: {
            //   layoutAnimation: "false",
            //   initLayout: "circular"
            // },
            roam: true,
            //categories:this.ca,
            lineStyle: {
              // 关系边的公用线条样式。其中 lineStyle.color 支持设置为'source'或者'target'特殊值，此时边会自动取源节点或目标节点的颜色作为自己的颜色。
              normal: {
                color: "#aaa", // 线的颜色[ default: '#aaa' ]
                width: 10, // 线宽[ default: 1 ]
                type: "solid", // 线的类型[ default: solid ]   'dashed'    'dotted'
                opacity: 1, // 图形透明度。支持从 0 到 1 的数字，为 0 时不绘制该图形。[ default: 0.5 ]
                curveness: 1, // 边的曲度，支持从 0 到 1 的值，值越大曲度越大。[ default: 0 ]
              },
            },
            // categories: this.legendLimit,
            categories: [
              {
                name: "本公司",
                itemStyle: {
                  label: {
                    show: true,
                  },
                  normal: {
                    color: "orange",
                  },
                  nodeStyle: {
                    brushType: "both",

                    borderWidth: 1,
                  },
                },
                symbolSize: 80,
              },
              {
                name: "上游产业",
                symbol: "roundRect",
                itemStyle: {
                  normal: {
                    color: "#4B0082",
                  },
                  nodeStyle: {
                    brushType: "both",
                    borderWidth: 1,
                  },
                },
                symbolSize: 50,
              },
              {
                name: "下游产业",
                itemStyle: {
                  normal: {
                    color: "#EA7CCC",
                  },
                  nodeStyle: {
                    brushType: "both",
                    borderWidth: 1,
                  },
                },
                symbolSize: 50,
              },
            ],
            label: {
              normal: {
                show: true,
                //position:'top',
                textStyle: {
                  fontSize: 12,
                  // color: "black"
                },
              },
            },
            edgeSymbol: ["none", "arrow"],
            edgeSymbolSize: [10, 7],
            edgeLabel: {
              normal: {
                show: true,
                textStyle: {
                  fontSize: 13,
                },
                formatter: "{c}",
              },
            },
            // data: graph_info["欣旺达"].data.nodes,
            data: this.graphData,
            // links: graph_info["欣旺达"].data.edge,
            links: this.graphLink,
            lineStyle: {
              normal: {
                opacity: 0.9,
                width: 1,
                curveness: 0,
              },
            },
          },
        ],
      };
      this.graphChart.setOption(option);
    },
  },
  mounted() {
    this.setOriginGraphChart();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.linkEcharts {
  .graphContainer {
    width: 96%;
    margin-left: 2%;
    height: 450px;
  }
  .graphChart {
    width: 100%;
    // height: 30vw;
    height: 100%;
    margin-top: 5px;
  }
}
</style>
