<template>
  <!-- 概况地图组件 -->
  <div class="OverviewMapBody">
    <MapInvestment :data="mapData" :pointColor="pointColor" :dataType="dataType" eheight="300px" style="width: 50%;"></MapInvestment>

    <div class="mapCicleBox">
      <div class="mapCicle" v-for="(item, index) in mapData" :key="index" :style="pointColor=='#FFFF33'? 'color: #FEFA83' : 'color: rgb(34, 171, 38)'" @click="handleClickCicle(item.region_name)">
        {{ item.region_name }}
      </div>
    </div>
  </div>
</template>
<script>
// import MapInvestment from "@/components/MapInvestment.vue"
import MapInvestment from "@/components/MapInvestment.vue";
export default {
  props: {
    mapData: Array,
    pointColor: String,
    dataType: String
  },
  data() {
    return {
      activeNames: ["1"],
      controlIcon: true,
    };
  },
  components: {
    MapInvestment,
  },
  methods: {
    // 响应点击圆圈的省份
    handleClickCicle(region) {
      this.$emit("handleClickCicle", region)
    },
    changeIcon() {
      if (this.controlIcon) {
        // console.log('1')
        this.$refs.OverviewMapIcon.style =
          "transform: rotate(90deg);transition: all 0.3s linear;";
      } else {
        // console.log('2')
        this.$refs.OverviewMapIcon.style =
          "transform: rotate(0deg); transition: all 0.3s linear;";
      }
      this.controlIcon = !this.controlIcon;
    },
  },
};
</script>
<style lang="scss">
.OverviewMapBody {
  display: flex;
  margin-bottom: 60px;
  .van-cell {
    flex-direction: row-reverse;
  }
  .van-collapse-item__content {
    color: #555;
    padding-top: 0rem;
  }
  .iconImg {
    height: 0.15rem;
    margin-right: 0.1rem;
  }
  .contentImg {
    width: 100%;
    // height: 2.75rem;
  }
  .mapCicleBox {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    // justify-content: space-evenly;
    margin-top: 20px;
    .mapCicle {
      cursor: pointer;
      height: 60px;
      width: 60px;
      background: #81d3f8;
      border-radius: 30px;
      line-height: 60px;
      text-align: center;
      margin-right: 5px;
      margin-bottom: 5px;
      white-space: nowrap;
      overflow: hidden;
      color: #FEFA83;
    }
  }
}
</style>